import React from "react";
import "../../index.css";
import "../PainManagement/PainManagement.css";

function PainManagement() {
  return (
    <>
      <div className="pain_management_banner common_banner_sec">
        <div className="banner_body">
          <div className="banner_wrapper">
            <div className="banner_wrpr_bg">
              <div className="service_banner_con">
                <div className="common_width">
                  <div className="banner_con_text">
                    <div className="banner_con_text1 font55">
                      <h1>Pain Management</h1>
                      <h5>
                        Call us <a className="banner_link" href="tel:(951) 808-5221">(951) 808-5221</a>{" "}
                        <span>
                          Visit us at 802 Magnolia Ave Ste 103 Corona, CA 92879
                        </span>
                      </h5>
                      <a href ="https://qualitypracticeweb.com/inlandlight"  target={"_blank"} className="green_button">
                        <h6>Request an appointment here</h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pain_management_block1">
        <div className="left_image_and_text">
          <div className="blue_text_background block-padding">
            <div className="common_width">
              <div className="text_position">
                <div className="textwrapper white-title-white-description">
                  <h2>
                    Pain Management in Corona, CA with Inland Empire Integrated
                    Medicine
                  </h2>
                  <p>
                    Being able to manage your pain can prove to be difficult
                    without the assistance of a physician. Here at Inland Empire
                    Integrated Medicine we want to help you treat and manage
                    your pain through the best means possible.
                  </p>
                  <p>
                    Don’t believe the misconceptions that pain is a normal part
                    of the aging process. While sometimes that can be the case,
                    your pain can also be mistaken for something more than what
                    you think. Aging does bring pain to the body, but you don’t
                    have to manage the pain alone or based solely on pain
                    medications. We can help you!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pain_management_block2 block-padding">
        <div className="common_width">
          <div className="block_green_bg">
            <div className="flex_wrapper">
              <div className="textwrapper blue-title-black-description">
                <h2>In What Ways Can Pain Be Managed?</h2>
                <p>
                  There are a few ways that pain can be managed or treated: 1)
                  medical pain management and 2) interventional pain management.
                  There are certain situations and conditions that require
                  medical pain management which involve medications where they
                  help with swelling and inflammation. There are also times
                  where the pain will continue to be unbearable and persistent
                  that additional measures are required.
                </p>
                <p>
                  However, there are times when medical pain management is
                  unsuccessful which makes interventional pain management the
                  next best option. The first step of this management would be
                  to complete a diagnostic imaging test to find out where the
                  pain is coming from specifically; typically this diagnostic
                  step comes after medication and before surgery.
                </p>
              </div>
              <div className="image_wrapper">
                <img src={`${process.env.REACT_APP_IMG_URL}IEIM_pain_management_block2_img.webp`} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pain_management_block3">
        <div className="right_image_and_text">
          <div className="sky_text_background block-padding">
            <div className="common_width">
              <div className="text_position">
                <div className="textwrapper blue-title-black-description">
                  <h2>
                    Different Pain Management Choices at Inland Empire
                    Integrated Medicine{" "}
                  </h2>
                  <p>
                    There are a few ways that pain can be managed or treated: 1)
                    medical pain management and 2) interventional pain
                    management. There are certain situations and conditions that
                    require medical pain management which involve medications
                    where they help with swelling and inflammation. There are
                    also times where the pain will continue to be unbearable and
                    persistent that additional measures are required.{" "}
                  </p>
                  <p>
                    However, there are times when medical pain management is
                    unsuccessful which makes interventional pain management the
                    next best option. The first step of this management would be
                    to complete a diagnostic imaging test to find out where the
                    pain is coming from specifically; typically this diagnostic
                    step comes after medication and before surgery.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PainManagement;
