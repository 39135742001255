import React from "react";
import "../Healthriskanalysis/Healthriskanalysis.css";
import "../../index.css";
import { useLocation } from "react-router-dom";

function Healthriskanalysis() {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  console.log("location ====", splitLocation);
  return (
    <>
      <div className="healthrisk_banner common_banner_sec">
        <div className="banner_body">
          <div className="banner_wrapper">
            <div className="banner_wrpr_bg">
              <div className="service_banner_con">
                <div className="common_width">
                  <div className="banner_con_text">
                    <div className="banner_con_text1 font55">
                      <h1>Health Risk Exam </h1>
                      <h5>
                        Call us <a className="banner_link" href="tel:(951) 808-5221">(951) 808-5221</a> {" "}
                        <span>
                          Visit us at 802 Magnolia Ave Ste 103 Corona, CA 92879
                        </span>
                      </h5>
                      <a
                        href="https://qualitypracticeweb.com/inlandlight"  target={"_blank"}
                        className="green_button"
                      >
                        <h6>Request an appointment here</h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="healthrisk_block1">
        <div className="left_image_and_text">
          <div className="blue_text_background block-padding">
            <div className="common_width">
              <div className="text_position">
                <div className="textwrapper white-title-white-description">
                  <h2>
                    Health Risk Exam in Corona, CA with Inland Empire
                    Integrated Medicine
                  </h2>
                  <p>
                    This is our early detection exam for potential disease
                    states. The TM-Flow machine tests for early signs of 20+
                    disease states which include heart attack risk, diabetes,
                    neuropathy, and other chronic conditions all in less than 15
                    minutes. We want to help our patients to avoid any life
                    threatening conditions especially if we can catch the signs
                    early to help prevent any diseases from progressing. It is
                    also covered by most insurances!
                  </p>
                  <p>
                    The health risk exam maps out the health of your
                    entire body including the autonomic nervous system and the
                    micro- and macro-vascular systems. It narrows down the area
                    of the body that needs treatment, which therefore helps the
                    doctors know what the next steps are to get you healthy
                    again.
                  </p>
                  <p>
                    At Inland Empire Integrated Medicine we care about our
                    patients and want to give them access to the best life
                    saving technology that is available. We sincerely value you
                    and we are passionate about your health, visit us today!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="healthrisk_block2 block-padding">
        <div className="common_width">
          <div className="block_sky_bg">
            <div className="flex_wrapper">
              <div className="textwrapper blue-title-black-description">
                <h2>The Exam Tests for:</h2>
                <div className="block_listwrapper">
                  <p>Heart Attack Risk</p>
                  <p>Diabetes with Complications</p>
                  <p>Post Covid Complications </p>
                  <p>
                    Nerve Damage (including that which can lead to organ
                    failure)
                  </p>
                  <p>Artery Inflammation and Disease</p>
                  <p>Obesity Complications</p>
                  <p>Plus more….</p>
                </div>
              </div>
              <div className="image_wrapper image_inner_wrapper">
                <img
                  src={`${process.env.REACT_APP_IMG_URL}healthrisk_block2.webp`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`greentitlebar ${splitLocation[1] === "" ? "hidebar" : ""}`}
      >
        <h2>
          Come in Right Away For Our <span>HEALTH RISK EXAM!</span>
        </h2>
        <div className="greentitle_button_wrapper">
          <button>
            <a href="https://qualitypracticeweb.com/inlandlight"  target={"_blank"}>Click Here</a>
          </button>
        </div>
      </div>
      <div className="healthrisk_block3">
        <div className="left_image_and_text">
          <div className="blue_text_background block-padding">
            <div className="common_width">
              <div className="text_position">
                <div className="textwrapper white-title-white-description">
                  <h2>Common Symptoms are:</h2>
                  <div className="block_listwrapper colun_count2">
                    <p>Blurred Vision</p>
                    <p>Elevated Blood Sugar</p>
                    <p>Extreme Thirst</p>
                    <p>Frequent Urination</p>
                    <p>Lightheadedness </p>
                    <p>Dizziness </p>
                    <p>Headache </p>
                    <p>Fatigue (Tiredness)</p>
                    <p>Heartburn</p>

                    <p>Increased Hunger</p>
                    <p>Nausea</p>
                    <p>Numbness & Tingling in Hands or Feet</p>
                    <p>Vomiting</p>
                    <p>
                      Angina (severe chest pain, often spreading to shoulder,
                      arm, back, neck, or jaw)
                    </p>
                    <p>Chest Pain </p>
                    <p>Pain in Calves</p>
                    <p>Shortness of Breath</p>
                    <p>Stroke</p>
                    <p>TIA (mini-stroke)</p>
                    <p>Burning Sensations</p>
                    <p>Painful Contact with Socks or Bed Sheets</p>
                  </div>
                  <p>
                    If you are experiencing any of these symptoms, you could be
                    at risk. Schedule an appointment today to find out more!{" "}
                    <span>
                      <a href="https://qualitypracticeweb.com/inlandlight1"  target={"_blank"}>
                        Click here
                      </a>
                    </span>{" "}
                    to see if you pre-qualify and get an appointment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="healthrisk_block4 block-padding">
        <div className="common_width">
          <div className="flex_wrapper">
            <div className="textwrapper blue-title-black-description">
              <h2>Disease States It Detects: </h2>
              <div className="block_listwrapper colun_count2">
                <p>Heart attack</p>
                <p>Heart rate variability issues</p>
                <p>Cardiac output to body surface area</p>
                <p>Abnormal LDL cholesterol levels</p>
                <p>Insulin resistance</p>
                <p>Diabetes with complications</p>
                <p>Physical and mental stress, anxiety, and fatigue</p>
                <p>Stroke</p>
                <p>Angiotensin activity</p>
                <p>Atherosclerosis</p>
                <p>Peripheral vascular disease</p>
                <p>Hyperhidrosis and hypohidrosis</p>
                <p>Impaired glucose intolerance</p>
                <p>Nerve damage</p>
                <p>Obesity</p>
                <p>Amyloid neuropathy</p>
                <p>Asthma</p>
                <p>Cardiovascular associated diseases</p>
                <p>Idiopathic neuropathy</p>
                <p>Multiple system dystrophy</p>
                <p>Postpartum dysfunctions</p>
                <p>Psychological conditions</p>
                <p>Autonomic failure</p>
                <p>Sjogren’s syndrome</p>
              </div>
            </div>

            <div className="image_wrapper image_wrapper_padding">
              <div className="image_inner_wrapper">
                <img
                  src={`${process.env.REACT_APP_IMG_URL}healthrisk_block4.webp`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="healthrisk_block5">
        <div className="left_image_and_text">
          <div className="blue_text_background block-padding">
            <div className="common_width">
              <div className="text_position">
                <div className="textwrapper white-title-white-description">
                  <h2>Why A Health Risk Exam is Essential:</h2>
                  <p>
                    Did you know that someone has a heart attack every 40
                    seconds? Nearly a million people have a heart attack per
                    year, 75% of them are their first. Our Health Risk Exam
                    catches the symptoms that lead to a heart attack nearly 2
                    years in advance. This is plenty of time to make changes in
                    your life to help prevent any further complications.
                  </p>
                  <p>
                    900,000 Americans die prematurely each year according to the
                    CDC, 40% of which could have been prevented. Technological
                    breakthroughs in the last ten years have tremendously
                    boosted the opportunities for doctors to be able to truly
                    help patients avoid disease as well as help treat them. We
                    are one of the new facilities that have adapted to this new
                    detection technology! Come in today!
                  </p>
                  <p>
                    8 out 10 people qualify for this exam, find out today so you
                    can have peace of mind. It helps you and it helps the
                    doctors understand with more accurate data on what the next
                    best steps for treatment for you are.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Healthriskanalysis;
