import store from "../../store";


// const accessToken = (store.getState().loginSlice?.token); /// access token
// const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token
export const defaultBodyBackend = ({source = null}) => {
  const accessToken = (store.getState().loginSlice?.token); /// access token
  const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token
  let modifiedBody = {
    "source": source,
    "condition": {
      "limit": 5,
      "skip": 0
    },
    "sort": {
      "type": "asc",
      "field": "priority"
    },
    "searchcondition": {},
    'token': accessToken,
    'email': userMail
  };
  return modifiedBody;
};

export const defaultPreviewBody = ({source = null}) => {
  const accessToken = (store.getState().loginSlice?.token); /// access token
  const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token
  let modifiedBody = {
    source: source,
    condition: {
      limit: 1,
      skip: 0
    },
    sort: {
      type: "desc",
      field: "_id"
    },
    searchcondition: { },
    'token': accessToken,
    'email': userMail,
  };
  return modifiedBody;
  
}


export const generateBody = ({source = null, data}) => {
  const accessToken = (store.getState().loginSlice?.token); /// access token
  const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token
  let modifiedBody = {
    "source": source,
    "condition": {
      "limit": 5,
      "skip": 0
    },
    "sort": {
      "type": "asc",
      "field": "_id"
    },
    "searchcondition": {},
    'token': accessToken,
    'email': userMail
  };

  Object.keys(data).forEach((curKey) => {
    if (data[curKey] !== undefined && curKey !== 'formId') modifiedBody.searchcondition[curKey] = data[curKey];
    if (Array.isArray(modifiedBody.searchcondition[curKey]) && modifiedBody.searchcondition[curKey].length == 0) delete modifiedBody.searchcondition[curKey];
  })
  return modifiedBody;
}

export const dataBody = ({source = null, data}) => {
  const accessToken = (store.getState().loginSlice?.token);
  const userMail = (store.getState().loginSlice?.userInfo.email); 
  let modifiedBody = {
    "source": source,
    "condition": {
      "limit": 5,
      "skip": 0
    },
    "sort": {
      "type": "asc",
      "field": "_id"
    },
    "data": {},
    // 'token': accessToken,
    // 'email': userMail
  };

  Object.keys(data).forEach((curKey) => {
    if (data[curKey] !== undefined && curKey !== 'formId') modifiedBody.data[curKey] = data[curKey];
    if (Array.isArray(modifiedBody.data[curKey]) && modifiedBody.data[curKey].length == 0) delete modifiedBody.data[curKey];
  })
  return modifiedBody;
}

export const defaultBodyFrontend = ({source = null}) => {
  let modifiedBody = {
    "source": source,
    "condition": {
      "limit": 6,
      "skip": 0
    },
    "sort": {
      "type": "asc",
      "field": "priority"
    },
    "searchcondition": { status: 1 },
    'relation': "Frontend"
  };
  return modifiedBody;
}


export const generateBodyFrontend = ({source = null, data}) => {
  const accessToken = (store.getState().loginSlice?.token); /// access token
  const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token
  let modifiedBody = {
    "source": source,
    "condition": {
      "limit": 5,
      "skip": 0
    },
    "sort": {
      "type": "asc",
      "field": "_id"
    },
    "searchcondition": {},
    // 'token': accessToken,
    // 'email': userMail,
    'relation': "Frontend"
  };
  Object.keys(data).forEach((curKey) => {
    if (data[curKey] !== undefined && curKey !== 'formId') modifiedBody.searchcondition[curKey] = data[curKey];
    if (Array.isArray(modifiedBody.searchcondition[curKey]) && modifiedBody.searchcondition[curKey].length == 0) delete modifiedBody.searchcondition[curKey];
  })
  return modifiedBody;
}
//-------------------for fast letter upper case-----------------------//
export function capitalizeFirstLetter(string) {  
  return string.charAt(0).toUpperCase() + string.slice(1);
}
//------------------for remove html tag-------------------------------//
export function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

/**
 * sets of charachters
 */
 let upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
 let lower = 'abcdefghijklmnopqrstuvwxyz'
 let digit = '0123456789'
 let char = '@#$!%^&*'
 let all = upper + lower + digit + char
 
 /**
  * generate random integer not greater than `max`
  */
 
 function rand (max) {
   return Math.floor(Math.random() * max)
 }
 
 /**
  * generate random character of the given `set`
  */
 
 function random (set) {
   return set[rand(set.length - 1)]
 }
 
 /**
  * generate an array with the given `length` 
  * of characters of the given `set`
  */
 
 function generate (length, set) {
   let result = []
   while (length--) result.push(random(set))
   return result
 }
 
 /**
  * shuffle an array randomly
  */
 function shuffle (arr) {
   let result = []
 
   while (arr.length) {
     result = result.concat(arr.splice(rand[arr.length - 1]))
   }
 
   return result
 }
 /**
  * do the job
  */
 export function generatePassword (length) {
   let result = [] // we need to ensure we have some characters
 
   result = result.concat(generate(1, upper)) // 1 upper case
   result = result.concat(generate(1, lower)) // 1 lower case
   result = result.concat(generate(1, digit)) // 1 digit
   result = result.concat(generate(1, char)) // 1 char
   result = result.concat(generate(length - 4, all)) // remaining - whatever
 
  //  console.log(result)
   return shuffle(result).join('') // shuffle and make a string
 }
 


// export default ;