import React from "react";
import "../MedicationManagement/MedicationManagement.css";

function MedicationManagement() {
  return (
    <>
      

      <div className="medication_banner common_banner_sec">
        <div className="banner_body">
          <div className="banner_wrapper">
            <div className="banner_wrpr_bg">
              <div className="service_banner_con">
                <div className="common_width">
                  <div className="banner_con_text">
                    <div className="banner_con_text1 font55">
                      <h1> Medication Management</h1>
                      <h5>
                        Call us <a className="banner_link" href="tel:(951) 808-5221">(951) 808-5221</a>{" "}
                        <span>
                          Visit us at 802 Magnolia Ave Ste 103 Corona, CA 92879
                        </span>
                      </h5>
                      <a href ="https://qualitypracticeweb.com/inlandlight"  target={"_blank"} className="green_button">
                        <h6>Request an appointment here</h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="medications_block1">
        <div className="left_image_and_text">
          <div className="blue_text_background block-padding">
            <div className="common_width">
              <div className="text_position">
                <div className="textwrapper white-title-white-description">
                  <h2>
                    Medication Management in Corona, CA with Inland Empire
                    Integrated Medicine
                  </h2>
                  <p>
                    Sometimes getting in to see a doctor or licensed physician
                    to refill or prescribe medications can prove to be
                    difficult, but at Inland Empire Integrated Medicine we can
                    help you! We have a certified nurse practitioner who can do
                    an examination and prescribe medication right here at our
                    practice. Call today to schedule an appointment!
                  </p>
                  <p>
                    There are cases where a patient’s pain, muscle spasms, or
                    inflammation is so severe that medication management is
                    necessary for the healing process. Our physicians are
                    licensed to prescribe patients with the proper pain
                    medication to help relieve your pain.
                  </p>
                  <p>
                    During a medication management appointment, you and our
                    physician discuss and review all of your medications. This
                    includes over the counter drugs as well as prescription
                    medicine which includes supplements, natural treatments, and
                    anything else you take or use for therapeutic intent. The
                    physician makes sure they are the right prescriptions and
                    can issue a refill if necessary.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MedicationManagement;
