import React, { useEffect, useRef, useState } from "react";
import '../Blog/Blog.css';
import { Grid, IconButton, LinearProgress, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../index2.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import './Blog.css'
import { clearBlogListing, getdataforbloglist } from "../BlogBackend/BlogReducer";
import { getEventData, randomId } from "../../helper/helperFunctions";

let reqBody = {
    condition: {
        limit: 6,
        skip: 0,
    },
    source: "blog_views",
    searchcondition: { status: 1 },
    sort: {
        type: "asc",
        field: "priority",
    },
    relation: "Frontend",
};

export default function Blog() {

    // ---------------------------------------------Invoking Hooks--------------------------------------------- //
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const scrollToBlog = useRef();

    // ----------------------------------------Global State from Redux---------------------------------------- //
    const resolved = useSelector((state) => state.layoutSlice.resolved["/blog"] ? state.layoutSlice.resolved["/blog"] : false); //for resolve
    const blogarray = useSelector((state) => state.blogSlice?.frontEndBlogData ? state.blogSlice.frontEndBlogData : state.blogSlice.blogData);
    const loading = useSelector((state) => state.blogSlice?.loading);

    // -------------------------------------------useEffect Section------------------------------------------- //
    useEffect(() => {
        if (!resolved) {
            // dispatch(clearBlogListing("Frontend List"));
            dispatch(getdataforbloglist(reqBody));
        }
        return () => dispatch(clearBlogListing("Frontend List"));
    }, []);

    // For auto scroll to blog in the blog page
    useEffect(() => scrollToBlog.current.scrollIntoView({ behavior: "smooth" }), [scrollToBlog]);

    useEffect(() => {
        console.log("Blog List ---> useEffect 3", blogarray);
        let resolveData = {
            path: "/blog-detail",
            resolveFunc: [getdataforbloglist],
            body: { ...reqBody, relation: 'Frontend Details' },
        };

        getEventData(blogarray, navigate, 'blog-list', 'blog-list-event', 'elem-event-data', '/blog-detail/', reqBody, 'blog-detail', resolveData, dispatch);
    }, [blogarray]);

    //----------------------------------------------Functions for return jsx-----------------------------------------------//
    const getTestiList = () => {
        const newArrayOfObj = blogarray.map(({
             
      
            createdon_datetime: creationTime,
            // image_show.original: imgUrl,
            image,
            description: desc,
            patient_name: patientName,
            video: videos,
            youtubeVideo: youtubeVideos,
            audio_exists: audios,
            ...rest
        }) => ({
            creationTime,
            imgUrl:image[0]?.url,
            desc,
            loaderPath:
                "https://all-frontend-assets.s3.amazonaws.com/inland-empire-integrated-medicine-images/imgpsh_fullsize_anim-min.jpeg",
            shareLink: ["Facebook", "Twitter", "Linkedin"],
            ...rest
        }));

        let blogList = {
            loaderPath: "https://all-frontend-assets.s3.amazonaws.com/inland-empire-integrated-medicine-images/imgpsh_fullsize_anim-min.jpeg",
            shareLinks: ["Facebook", "Twitter", "Linkedin"],
            initialArr: newArrayOfObj,
            apiCall: {
                apiUrl: `${process.env.REACT_APP_API_URL}/api1/bloglisting`,
                body: {
                    source: "blog_views",
                    condition: {
                        limit: 6,
                        skip: 0,
                    },
                    searchcondition: { status: 1 },
                    sort: {
                        type: "asc",
                        field: "priority",
                    },
                    relation: "Frontend",
                }
            },
        }
        return (<blog-list key={randomId} data={JSON.stringify(blogList)} />)
    }

    return (
        <>
            <div className="blog_img">
                <img src="https://all-frontend-assets.s3.amazonaws.com/IEIM/ieim_blog_banner.webp" alt="" />
            </div>

            <div className="Blog_holdr" style={{ margin: "" }}>
                <div className="Blog_main_wrapper">
                    <div className="blog_block2 block-padding">

                        <Grid className="common_width margin_wdth">
                            <Grid className="heading">
                                {" "}
                                <h2 ref={scrollToBlog}>On The Blog</h2>{" "}
                            </Grid>


                            <div className="blg_crd_all_wpr">


                                {/* <------------------------------Blog Card List From angular element------------------------------> */}
                                {blogarray && blogarray.length > 0 ?
                                    getTestiList() : <div className='noDataAvailable' ><img src="https://all-frontend-assets.s3.amazonaws.com/Galveston/assets/images/no_result_image.png" /></div>
                                }
                            </div>


                        </Grid>
                    </div>
                    {loading ? <LinearProgress className="" /> : null}

                </div>
            </div>


        </>
    )
}
