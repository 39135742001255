import React from 'react'
import '../Enfdtesting/Enfdtesting.css'

function Enfdtesting() {
    return (
        <>
            <div className='Enfdtesting_banner common_banner_sec'>
                <div className='banner_body'>
                    <div className='banner_wrapper'>
                        <div className='banner_wrpr_bg'>
                            <div className='banner_con'>
                                <div className='common_width'>
                                    <div className='banner_con_text'>
                                        <div className='banner_con_text1'>
                                            <h1>ENFD    <br /> Testing </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Enfdtesting_block2'>
                <div className='common_width'>
                    <div className='Enfdtesting_colcontainer'>
                        <div className='Enfdtesting_block2_left'>
                            <div className='Enfdtesting_imgwrapper'>
                            <div className='Enfdtesting_subimgwrapper'>
                                <img src="https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/Enfdtesting_image2.jpg" />
                            </div>
                            </div>
                        </div>
                        <div className='Enfdtesting_block2_right'>
                            <div className='textwrapper'>
                                <h2>WHAT IS ENFD TESTING?</h2>
                                <p>Studies at Cleveland Clinic states that pain is inherently subjective and difficult to measure. The clinical exam was about 54% accurate when diagnosing small fiber neuropathy.</p>
                                <p>Aslo, the various electrical neurodiagnostic testings such as an EMG and nerve conduction velocity <span> (NCV) </span>  cannot detect dysfunction of the smaller pain fibers and autonomic nerves. These are nerves that are partly, if not entirely, responsible for many of the symptoms present in the neck, back, and extremities. Even blood lab tests provide no identifiable abnormalities. </p>
                                <p>However, through a proprietary testing method, skin biopsy is effective at finding if you have small fiber neuropathy.  The pathologist is able to look at a tiny speck of skin under a microscope and see how degenerated or disintegrated the small fiber nerves appear. Knowledge is a powerful step toward recovery.  At <span>Health and Pain Care</span>, we will be able to work with you along each step – from diagnostic testing to recovery modalities and strategies.  We’re in your corner and
                                    around the corner.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Enfdtesting
