import React from "react";
import "../../../index.css";
import "../AdvancedBiologics.css";

function AdvancedBio1() {
  return (
    <div>
      <div className="healthrisk_block1 AdvancedBiologics_wrpr1">
        <div className="left_image_and_text">
          <div className="blue_text_background block-padding">
            <div className="common_width">
              <div className="text_position">
                <div className="textwrapper white-title-white-description">
                  <h2>The Future of Regenerative medicine:</h2>
                  <p>
                    In the present day context regenerative medicine is the
                    future of wound care. The utilization of the power of
                    cell-derived signaling molecules has been proven to be a
                    more effective means for wound healing. It isn’t just about
                    the cell, rather it’s about the regenerative qualities of
                    cell-secreted proteins. We are pioneering the use of
                    cell-derived proteins and nano vesicles derived from
                    immunologically privileged tissues for a variety of
                    applications, providing better healthcare solutions and
                    outcomes for both patients and providers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="healthrisk_block2 AdvancedBiologics_wrpr2 block-padding">
        <div className="common_width">
          <div className="">
            <div className="flex_wrapper">
              <div className="textwrapper blue-title-black-description">
                <h2>4 Types of wounds we specialize in:</h2>
                <div className="block_listwrapper">
                  <p>
                    Arterial pressure ulcers: Arterial pressure ulcers result
                    from skin and soft tissue ischemia due to arterial-occlusive
                    disease. They are associated with hypertension, diabetes,
                    chronic kidney disease, and smoking. Various methods of
                    pressure offloading have strong evidence of effectiveness in
                    prevention of pressure ulcers.
                  </p>
                  <p>
                    Venous pressure ulcers: Venous ulcers typically occur
                    because of damage to the valves inside the leg veins. These
                    valves control the blood pressure inside the veins. They
                    allow it to drop when you walk. If the blood pressure inside
                    your leg veins doesn’t fall as you’re walking, the condition
                    is called sustained venous hypertension. That increase in
                    blood pressure cause ulcers to form on the ankles.
                  </p>
                  <p>
                    Diabetic neuropathy wounds: Diabetic foot ulceration is a
                    serious complication of diabetes mellitus worldwide and the
                    most common cause of hospitalization in diabetic patients.
                    The etiology of diabetic foot ulcerations is complex due to
                    their multifactorial nature; in the pathophysiology of
                    diabetic foot ulceration polyneuropathy is important. Proper
                    adherence to standard treatment strategies and
                    interdisciplinary cooperation can reduce the still high
                    rates of major amputations.{" "}
                  </p>
                  <p>
                    Pressure injury: Pressure injury also known as pressure
                    ulcers are decubitus ulcers - are injuries to skin and
                    underlying tissue resulting from prolonged pressure on the
                    skin. Pressure injury most often develop on skin that covers
                    bony areas of the body, such as the heels, ankles, hips and
                    tailbone. People most at risk of pressure injury have
                    medical conditions that limit their ability to change
                    positions or cause them to spend most of their time in bed
                    or chair.
                  </p>
                 
                </div>
              </div>
              <div className="image_wrapper image_inner_wrapper">
                <div className="img_innr_inn">
                <img src="https://all-frontend-assets.s3.amazonaws.com/IEIM/assets/advnced_bio_img2.png" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="healthrisk_block1 AdvancedBiologics_wrpr3">
        <div className="left_image_and_text">
          <div className="blue_text_background block-padding">
            <div className="common_width">
              <div className="text_position">
                <div className="textwrapper white-title-white-description">
                  <h4>What do we Offer?</h4>
                  <p>
                  Specialized Grafting for the healing of chronic and complicated wounds
                  </p>
                  <h3 className="font_color">Expedite wound closure between 4-6 weeks on average</h3>
                  <div className="gred_color"><a href="https://all-frontend-assets.s3.amazonaws.com/iwc_pdf/PRODUCTS_FOR_GENERAL_WOUND_CARE_MANAGEMENT.pdf" target="_blank"><h3>Natural Biosynthetic Products</h3></a></div>
                  <p>Treatment of Diabetic Ulcers, Pressure Sores, Trauma Wounds and Burns. Expedited Wound Closure for Pain Reduction and Infection Control</p>
                  <h5>A potential alternative to leg amputation, healing with native tissue and less scarring.</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="healthrisk_block2 AdvancedBiologics_wrpr4 block-padding">
        <div className="common_width">
          <div className="">
            <div className="flex_wrapper">
              <div className="textwrapper blue-title-black-description">
                <h2>Advanced Wound Care Products:</h2>
                <p>Our advanced wound care products are specifically designed for regenerative wound care which is all about the regenerative qualities of the cell-secreted proteins.</p>
                <h3 className="font_black">Our advanced wound care products help:</h3>
               
                <div className="block_listwrapper">
                  <p>
                  Repair chronic wound                         
                   </p>
                  <p>
                  Venous leg ulcers 
                  </p>
                  <p>
                  Diabetic foot ulcers  
                  </p>
                  <p>
                  Pressure ulcers
                  </p>
                 
                </div>
              </div>
              <div className="image_wrapper image_inner_wrapper">
                <div className="img_height">
                <img src="https://all-frontend-assets.s3.amazonaws.com/IEIM/assets/advnced_massage_img.webp" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="healthrisk_block1 AdvancedBiologics_wrpr5">
        <img className="derma_adv_abs_img" src="https://all-frontend-assets.s3.amazonaws.com/IEIM/assets/advnced_dermacyte_img.png"/>
        <div className="left_image_and_text">
          <div className="blue_text_background block-padding">
            <div className="common_width">
              <div className="text_position">
                <div className="textwrapper white-title-white-description">
                  <h2>Dermacyte:</h2>
                </div>
                <div className="block_listwrapper colun_count2">
                   <p>AMNION ANATOMY & PHYSIOLOGY</p>
                   <p>Amnion (inner layer) </p>
                   <p>Anatomy</p>
                   <p> Chorion (outer layer)</p>
                </div>
                <div className="textwrapper white-title-white-description">
                    <h2>Physiology</h2>
                
                </div>
                <div className="coloumn_flex">
                <div className="block_listwrapper adv_lft font_white">
                   <p>Collagen Type - I, III, IV, V, and VI</p>
                   <p>Hyaluronic Acid </p>
                   <p>Fibroblast Growth Factors (FGFs)</p>
                   <p> Proteins and vesicles IN SOLUTION</p>
                   <p>Elastin</p>
                   <p>Transforming Growth Factor Beta (TGF-β)</p>
                </div>
                <div className="block_listwrapper adv_rght">
                   <p>Platelet Derived Growth Factors (PDGF) A&B</p>
                   <p>Proteins </p>
                    <ul>
                        <li>CHEMOKINES (E.G. CXCL 14)</li>
                        <li>CYTOKINES (E.G. ADIPONECTIN)</li>
                        <li>GROWTH FACTORS</li>
                    </ul>
                   <p>Extracellular Vesicles (e.g. exosomes)</p>
                   
                </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default AdvancedBio1;
