import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, LinearProgress, Tooltip } from '@mui/material';
import "../../index.css";
import "./VideoGallery.css";
import { clearVideoDetails, getdataforVideolist, setVideoID } from '../GalleryBackend/VideoGalleryBackend/VideoGalleryReducer';
import HelpIcon from '@mui/icons-material/Help'
import AddIcon from '@mui/icons-material/Add';
import DescriptionModification from '../Blog/DescriptionModification';

const reqBody = {
  condition: {
    limit: 6,
    skip: 0,
  },
  searchcondition: { "status": 1 },
  source: "video_views",
  sort: { type: "asc", field: "priority" },
  projection: {
    video_thumbnail: 1,
    videoid: 1,
    youtubeVideo: 1,
    title: 1,
    description: 1,
  },
  relation: 'Frontend'
};


export default function VideoGallery() {


  // ---------------------------------------------Invoking Hooks--------------------------------------------- //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollToVideoTube = useRef();


  const [openDescriptionModificationModal, setDescriptionModificationModal] =useState(false); //for Description Modification for Meta

  const [videoID, setVideoId] = useState(null); //for Description Modification for Meta
  const [videoDetailsID, setVideoDetailsID] = useState(null); //for Description Modification for

  const formSubmissionState = useSelector((state) => state.formSlice.formSubmissionState.descriptionModification);

  const videoIDdetails = useSelector((state) =>state.videogallerySlice.videoID !== null && state.videogallerySlice.videoID !== undefined &&state.videogallerySlice.videoID );


  const videoFirstId = useSelector((state) =>state.videogallerySlice?.videoGalleryData[0]?._id? state.videogallerySlice.videoGalleryData[0]?._id: "");
        console.log("videoFirstId=====>", videoFirstId);


  // ----------------------------------------Global State from Redux---------------------------------------- //
  const resolved = useSelector(state => state.layoutSlice.resolved['/videogallery'] ? state.layoutSlice.resolved['/videogallery'] : false);//for resolve
  const videoArr = useSelector(state => (state.videogallerySlice?.videoGalleryData) ? state.videogallerySlice.videoGalleryData : '');
  const loading = useSelector(state => state.videogallerySlice?.loading);

  // ----------------------------------------Comonents Private State---------------------------------------- //
  const [videoDetail, setVideoDetail] = useState(null);

  let videoDetails = "";
  let videoDetailsID1 = "";

  // -------------------------------------------useEffect Section------------------------------------------- //
  useEffect(() => {
    if (!resolved && videoArr.length === 0) {
    dispatch(clearVideoDetails("Frontend Details"));
    dispatch(getdataforVideolist(reqBody));
    }
    return () => dispatch(clearVideoDetails("Frontend Details"));
  }, [])

  // For auto scroll to video tube in video gallery
  useEffect(() => scrollToVideoTube.current.scrollIntoView({ behavior: "smooth" }), [scrollToVideoTube]);

  // For setting 'video-detail' element data
  useEffect(() => {
    if (videoArr && videoArr.length > 0) {
      let shareLinks = ["Facebook", "Twitter", "Linkedin"];
      let loaderPath = "https://all-frontend-assets.s3.amazonaws.com/inland-empire-integrated-medicine-images/imgpsh_fullsize_anim-min.jpeg";

      let newArrayOfObj = videoArr.map(currObj => ({ ...currObj, shareLinks, loaderPath }));
      console.log("newArrayOfObj ====", newArrayOfObj);

      setVideoDetail({
        apiCall: {
          // apiUrl: "https://backend.galvestonspine.peceplatform.com/api/videolisting",
          apiUrl: `${process.env.REACT_APP_API_URL}/api1/videolisting`,
          body: { ...reqBody },
        },
        initialArr: newArrayOfObj,
        _id: newArrayOfObj[0]._id,
        thumbnail: newArrayOfObj[0].video_thumbnail,
        videoid: newArrayOfObj[0].videoid,
        loaderPath: loaderPath,
        title: newArrayOfObj[0].title,
        desc: newArrayOfObj[0].description,
        shareLink: shareLinks,
        // imgGalMainWrap: "cardWrapper",
        // imgWrap: "wrapImg",
        // imgGalContWrap: "wrapCont",
      });
    }
  }, [videoArr]);

  // For perform action on element event
  // useEffect(() => getEventData(videoArr, navigate, 'video-detail', 'video-detail-event', 'elem-event-data', null, null, '/videogallery'), [videoArr]);

  useEffect(() => {
    // console.log("singleTestimonial ------------>", singleTestimonial);
    let domNodeArr = null;
    setTimeout(() => {
      domNodeArr = document.querySelectorAll('video-detail');
      if (domNodeArr && domNodeArr.length > 0) {
        for (let i = 0; i < domNodeArr.length; i++) {
          if (domNodeArr[i]) domNodeArr[i].addEventListener('video-detail-event', (e) => {
            let eventData = JSON.parse(e.target.getAttribute('elem-event-data'));
            switch (eventData.type) {
              // case "read_more": {
              //   navigate(`/frontend/testimonial-details/${eventData._id}`);
              //   window.location.reload();
              // }
              //   break;
              case "shareInFacebook": window.open(`https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_API_EXTERNAL_URL}video-gallery/${eventData._id}`, "", "width=500,height=300");
                break;
              case "shareInTwitter": window.open(`https://twitter.com/intent/tweet?url=${process.env.REACT_APP_API_EXTERNAL_URL}video-gallery/${eventData._id}`, "", "width=500,height=300");
                break;
              case "shareInLinkedin": window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${process.env.REACT_APP_API_EXTERNAL_URL}video-gallery/${eventData._id}`, "", "width=500,height=300");
                break;
              default: console.log("Inside Default Case of switch-case");
                break;
            }
          });
        }
      }
    }, 1000);

  }, [videoArr]);

  const handleOpenDescriptionModification = () => {
    
    setTimeout(() => {
      setDescriptionModificationModal(true);
    }, 500);
  };

  const handleCloseDescriptionModification = () => {
    setDescriptionModificationModal(false);
  };


  useEffect(() => {
    if (formSubmissionState && formSubmissionState == 2) {
      setDescriptionModificationModal(false);
    }
  }, [formSubmissionState]);

  useEffect(() => {
    setTimeout(() => {
      videoDetails = document
        .querySelector("video-detail")
        ?.getAttribute("video-data");
      console.log("data111111", videoDetails);

      videoDetailsID1 = JSON.parse(videoDetails)._id;
      setVideoDetailsID(videoDetailsID1);
      console.log("data1111112", videoDetailsID1);
      dispatch(setVideoID(videoDetailsID1));
    }, 500);
  }, [openDescriptionModificationModal]);



  return (
    <>
      <div className="videogallery_banner common_banner_sec">
        <div className="banner_body">
          <div className="banner_wrapper">
            <div className="banner_wrpr_bg">
              <div className="service_banner_con">
                <div className="common_width">
                  <div className="banner_con_text">
                    <div className="banner_con_text1 font55">
                      <h1>Video Gallery </h1>
                      <h5>
                      Call us <a className="banner_link" href="tel:(951) 808-5221">(951) 808-5221</a>

                        <span>
                          Visit us at 802 Magnolia Ave Ste 103 Corona, CA 92879
                        </span>
                      </h5>
                      <a
                        href="https://qualitypracticeweb.com/inlandlight" target={"_blank"}
                        className="green_button"
                      >
                        <h6>Request an appointment here</h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 

      <div>

        <div className='vdo_bttn block-padding'>
          <div className="common_width">
            <div className="heading">
              {/* <h2 ref={scrollToVideoTube}> Check out our latest uploads  </h2> */}
              <h1 ref={scrollToVideoTube}>Check out our latest uploaded videos of Inland Empire Integrated Medicine</h1>


              <div className="add_testimonial_btnwrapper">
              <Button className="video_details"   onClick={() => handleOpenDescriptionModification()}>Coustom Meta</Button>


          </div>

              <div className='galleryloader' >{loading ? <LinearProgress className='' /> : null}</div>
            </div>

            {/* {videoArray.length > 0 && videoArray[0]?._id != undefined && <VideoTube videos={videoArray} />} */}

            {/* <------------------------------Video Gallery from angular element------------------------------> */}
            {videoDetail ? <video-detail data={JSON.stringify(videoDetail)}></video-detail> : <div className='noDataAvailable' ><img src="https://all-frontend-assets.s3.amazonaws.com/Galveston/assets/images/no_result_image.png" /></div>}
          </div>
        </div>

        <DescriptionModification
        open={openDescriptionModificationModal}
        close={handleCloseDescriptionModification}
        id={videoID}
        dynamicEndPoint="api1/update-custom-meta-title"
        source="videos"
        firstId={videoFirstId ? videoFirstId : undefined}
        detailid={videoIDdetails}
      />
      </div>


    </>
  )
}