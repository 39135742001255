import { Box, IconButton, LinearProgress, Modal, Snackbar, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from 'react-redux';
import { Form } from '../../form/Form'


function DescriptionModification({open,close,id,dynamicEndPoint,firstId,detailid,source}) {
    console.log("firstId======>",firstId);
    console.log("detailid======>",detailid);
    console.log("dynamicEndPoint====>",dynamicEndPoint);

    const [loading, setLoading] = useState(false);

    const [openSnackbar, setOpenSnackbar] = useState(false);

    const formSubmissionState = useSelector(state => state.formSlice.formSubmissionState.descriptionModification);

    const successMsg = useSelector((state) => state.formSlice.message?.descriptionModification); //for snackbar massge

    const dataform = {

        id: 'descriptionModification',
        api_url: process.env.REACT_APP_API_URL,
        endPoint: dynamicEndPoint,
        endPointBodyWrapper: "data",
        
        submitBtnName: "Submit",
        resetForm: true,
        resetBtnName: "Reset",
        formButtonClass: "submitbuttonwrapper ",
    
        formAdditionalSubmissionData: {
          
          _id:detailid?detailid:firstId,
          source:source
    
        },

        fields: [
            {
                id: 0,
                heading: "Custom Meta Title",
                lable: "Custsourceom Meta Title",
                name: "custom_meta_title",
                className: "list_search_item_added countDiv__column--col12",
                type: "text",
              },

              {
                id: 1,
                heading: "Custom Meta Description",
                lable: "Custom Meta Description",
                placeholder: "TYPE HERE!!",
                name: "custom_meta_description",
                className: "textareaaa",
                rows: 10,
                type: "textarea",
              },
        ]


    }

    useEffect(() => {
        if (formSubmissionState) setLoading(true);
        if (formSubmissionState && formSubmissionState == 2) {
          setLoading(false);
          setOpenSnackbar(true);
        }
      }, [formSubmissionState]);








  return (
    <div>

    <div>
    
    
         
    <Modal
    
    
            open={open}
            onClose={close}
            classname="image_card_modal modalBaseStyle"
          >
       
            <Box className="modalBaseBox">
            <Tooltip title='Close' >
              <IconButton
                className="modal_icon_button"
                onClick={close}
              >
                <CloseIcon className="modal_close_icon" />
              </IconButton>
              </Tooltip>
             
              <div>
                <h2 className='modalTopTitle'>Custom Meta</h2>
           <Form formData={dataform} />
           {loading && loading ? <LinearProgress className='' /> : null}
           </div>
             
            </Box>
          </Modal>
        </div>
    
        <Snackbar
              open={openSnackbar}
              onClose={() => setOpenSnackbar(false)}
              autoHideDuration={2000}
              message={successMsg}
            />
    
    
    
    
        </div>
  )
}

export default DescriptionModification