import React from "react";
import { useLocation } from "react-router-dom";
import "../../index.css";
import "../SpinalDecompressionTherapy/SpinalDecompressionTherapy.css";
function SpinalDecompressionTherapy() {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  console.log("location ====", splitLocation);

  return (
    <>
      <div className="spinal_decom_banner common_banner_sec">
        <div className="banner_body">
          <div className="banner_wrapper">
            <div className="banner_wrpr_bg">
              <div className="service_banner_con">
                <div className="common_width">
                  <div className="banner_con_text">
                    <div className="banner_con_text1  font55">
                      <h1>Spinal Decompression Therapy</h1>
                      <h5>
                        Call us <a className="banner_link" href="tel:(951) 808-5221">(951) 808-5221</a>{" "}
                        <span>
                          Visit us at 802 Magnolia Ave Ste 103 Corona, CA 92879
                        </span>
                      </h5>
                      <a href ="https://qualitypracticeweb.com/inlandlight"  target={"_blank"} className="green_button">
                        <h6>Request an appointment here</h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="spinal_decom_block1">
        <div className="left_image_and_text">
          <div className="blue_text_background  block-padding">
            <div className="common_width">
              <div className="text_position">
                <div className="textwrapper  white-title-white-description">
                  <h2>
                    Spinal Decompression Therapy in Corona, CA with Dr. Robert S. Krage D.C.
                  </h2>
                  <p>
                    Spinal decompression therapy is a non-invasive, non-surgical
                    procedure in which a chiropractor gently removes internal
                    pressure from the spinal joints in the neck or lower back
                    with a traction device. This therapy helps with a variety of
                    conditions like headaches, sciatica, lower back pain, and
                    disc issues.
                  </p>
                  <p>
                    Hundreds of people suffer from back pain in southern
                    California, but here at Inland Empire Integrated Medicine in
                    Corona we offer solutions through non-invasive treatments.
                    Schedule a consultation today!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="spinal_decom_block2">
        <div className="block-padding">
          <div className="common_width">
            <div className="full_width_heading_para textwrapper">
              <h2>How Does Spinal Decompression Therapy Work?</h2>
              <p>
                You are lying down on a table with straps around the pelvic
                area, with cushions behind the knees and the neck, this
                treatment involves a motorized traction device that is strapped
                to the neck or back depending on the area in pain, and a vacuum
                effect (known as spinal imbibition) stretches the spine so that
                shifted or displaced discs can return to their natural position.
                Thereby releasing neural decompression and rehydrating the discs
                by allowing blood and nutrients to flow more easily to the rest
                of the body.
              </p>
            </div>

            <div className="left_text_and_video">
              <div className="textwrapper blue-title-black-description">
                <p>
                  This therapy treatment is overseen by an experienced
                  healthcare professional which is Dr. Krage. During the
                  session, the machine is programmed to pull for 45 to 60
                  seconds then the machine releases 30% to 50% depending on the
                  patient.
                </p>
                <p>
                  The treatment only lasts between{" "}
                  <span>30 to 35 minutes,</span> so it’s easy to fit into your
                  busy schedule.
                </p>
                <p>
                  As previously mentioned, the decompression machine and the way
                  it is programmed is based solely on the conditions and
                  severity of pain from the patient, these dictate the pull and
                  the angle of the pull from the device.
                </p>
                <p>
                  During the spinal decompression treatment, the traction will
                  pull the spine which will enclose an injured disc within
                  several millimeters of the goal in reducing the lower back or
                  neck pain of the patient with minimal pain.{" "}
                </p>
              </div>
              <div className="right_video_wrapper">
                <div className="right_video_section">
                  <iframe
                    src="https://www.youtube.com/embed/z1pzJkNxfo8?rel=0"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`greentitlebar ${splitLocation[1] === "" ? "hidebar" : ""}`}
      >
        <h2>
          Come in Right Away For Our <span>HEALTH RISK EXAM!</span>
        </h2>
        <div className="greentitle_button_wrapper">
          <button>
            <a href="https://qualitypracticeweb.com/inlandlight"  target={"_blank"}>Click Here</a>
          </button>
        </div>
      </div>

      <div className="spinal_decom_block3">
        <div className="left_image_and_text">
          <div className="blue_text_background block-padding">
            <div className="common_width">
              <div className="text_position">
                <div className="textwrapper  white-title-white-description">
                  <h2>What does Spinal Decompression Do?</h2>
                  <div className="block_listwrapper white-title-white-description">
                    <p>Eliminates lower back and neck pain </p>
                    <p>Reduces pressure on the spinal joints </p>
                    <p>
                      Helps reinforce the retraction of bulged and herniated
                      discs
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="spinal_decom_block4">
        <div className="block-padding sky_text_background">
          <div className="common_width">
            <div className="flex_wrapper">
              <div className="textwrapper blue-title-black-description">
                <h2>Benefits of Spinal Decompression Therapy:</h2>
                <h6>
                  There are a variety of benefits to spinal decompression, a few
                  include:{" "}
                </h6>
                <div className="block_listwrapper colun_count2">
                  <p>Non-invasive</p>
                  <p>Clinically validated by thousands of patients </p>
                  <p>Treatment time is short </p>
                  <p>Can reduce or completely eliminate chronic conditions</p>
                  <p>Can eliminate sciatica </p>
                  <p>It is NOT painful </p>
                  <p>Cost-effective </p>
                  <p>Safe</p>
                  <p>Non-pharmaceutical</p>
                  <p>Significantly reduces pain</p>
                </div>
              </div>

              <div className="image_wrapper image_wrapper_padding">
                <div className="image_inner_wrapper">
                  <img
                    src={`${process.env.REACT_APP_IMG_URL}IEIM_spinal_decom_block4_img.webp`}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="spinal_decom_block5">
        <div className="left_image_and_text">
          <div className="blue_text_background block-padding">
            <div className="common_width">
              <div className="text_position">
                <div className="textwrapper  white-title-white-description">
                  <h2>What Conditions can Spinal Decompression Treat?</h2>
                  <div className="block_listwrapper colun_count2">
                    <p>Sciatica </p>
                    <p>Chronic, long-term headaches</p>
                    <p>Bulging / Herniated Disc(s)</p>
                    <p>Degenerative Disk Disease</p>
                    <p>Posterior Facet Syndrome </p>
                    <p>Acute or Chronic Back and/or Neck Pain</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="spinal_decom_block6">
        <div className="block-padding">
          <div className="common_width">
            <div className="flex_wrapper">
              <div className="textwrapper blue-title-black-description">
                <h2>Why Spinal Decompression is Right for You:</h2>
                <div className="block_listwrapper">
                  <p>
                    100% guaranteed non-invasive. Spinal decompression therapy
                    is painless and does not require any recovery time. It is
                    safer and less expensive than spinal surgery which carries
                    higher risks and recovery time.{" "}
                  </p>
                  <p>
                    This therapy has been clinically proven. Thousands of
                    patients have had positive experiences with this treatment
                    method.{" "}
                  </p>
                  <p>
                    Time efficient. The sessions generally only last 30 minutes
                    meaning patients are only absent from work or kids for a
                    short period. Patients can usually go back to their normal
                    lives without issues.
                  </p>
                  <p>
                    Cost-effective. This treatment is far less expensive than a
                    spinal surgery, and has far less effects.{" "}
                  </p>
                </div>
              </div>

              <div className="image_wrapper image_wrapper_padding">
                <div className="image_inner_wrapper">
                  <img
               
                    src={`${process.env.REACT_APP_IMG_URL}IEIM_spinal_decom_block6_img.webp`}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}

export default SpinalDecompressionTherapy;
