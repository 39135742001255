import React from "react";
import { useNavigate } from "react-router-dom";
import "../../index.css";
import "./AdvancedBiologics.css";
import AdvancedBio1 from "./component/AdvancedBio1";
import AdvancedBio2 from "./component/AdvancedBio2";

function AdvancedBiologics() {

  const navigate = useNavigate();

  return (
    <div>
      <div className="Advanced_bio_bnnr common_banner_sec">
        <div className="banner_body">
          <div className="banner_wrapper">
            <div className="banner_wrpr_bg">
              <div className="service_banner_con">
                <div className="common_width">
                  <div className="banner_con_text">
                    <div className="banner_con_text1 font55">
                      <h1>Advanced Biologics</h1>
                      <h5>
                          Call <a className="banner_link" href="tel:(732) 249-9800">(732) 249-9800</a> or <a className="banner_link" onClick={() => navigate('/contact')}>Contact Us</a> to Request an Appointment
                      </h5>
                      {/* <a
                        href="https://qualitypracticeweb.com/inlandlight"
                        target="_blank"
                        className="green_button"
                      >
                        <h6>Request an appointment here</h6>
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdvancedBio1 />
      <AdvancedBio2 />
    </div>
  );
}

export default AdvancedBiologics;
