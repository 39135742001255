import React from "react";
import "../../index.css";
import "../LaserTherapy/LaserTherapy.css";

function LaserTherapy() {
  return (
    <>
   

      <div className="laser_banner common_banner_sec">
        <div className="banner_body">
          <div className="banner_wrapper">
            <div className="banner_wrpr_bg">
              <div className="service_banner_con">
                <div className="common_width">
                  <div className="banner_con_text">
                    <div className="banner_con_text1 font55">
                      <h1>Class IV Laser Therapy</h1>
                      <h5>
                        Call us <a className="banner_link" href="tel:(951) 808-5221">(951) 808-5221</a>{" "}
                        <span>
                          Visit us at 802 Magnolia Ave Ste 103 Corona, CA 92879
                        </span>
                      </h5>
                      <a href ="https://qualitypracticeweb.com/inlandlight" className="green_button">
                        <h6>Request an appointment here</h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="laser_block1">
        <div className="left_image_and_text">
          <div className="blue_text_background block-padding">
            <div className="common_width">
              <div className="text_position">
                <div className="textwrapper white-title-white-description">
                  <h2>
                    Class IV Laser Therapy in Corona, CA with Robert S. Krage D.C.
                  </h2>
                  <p>
                    Class IV laser therapy offers patients natural pain relief
                    and promotes healing on the cellular level. This practice
                    chooses to depend on holistic methods rather than depending
                    on surgery or prescribed medication for relief and
                    treatment.
                  </p>
                  <p>
                    This new, advanced technology allows us as healthcare
                    professionals to take helping patients to a whole new level!
                    It’s an exciting addition to our practice at Inland Empire
                    Integrated Medicine because it truly does relieve patients
                    of their pain and greatly helps their recovery time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="laser_block2 block-padding">
        <div className="common_width">
          <div className="block_green_bg">
            <div className="flex_wrapper">
              <div className="textwrapper blue-title-black-description">
                <h2>What is Class IV Laser Therapy?</h2>
                <p>
                  Class IV laser therapy is a unique type of treatment that uses
                  a special frequency of concentrated laser light that can
                  penetrate the top tissue of skin on the body without causing
                  any pain, damage, or discomfort. Dr. Krage will use a
                  specialized tool to direct the laser to your lower tissues to
                  stimulate cells to produce the substance ATP. Cells use ATP to
                  repair themselves and to regenerate more cells which repair in
                  greater areas.{" "}
                </p>
                <p>
                  This therapy is significant because it reaches tissues and
                  areas other techniques cannot even with intensive chiropractic
                  care. Another benefit is that this therapy increases the blood
                  flow at the given treatment site. Better blood flow equals
                  more nutrients coursing through the body and reduced edema.
                </p>
              </div>
              <div className="image_wrapper">
                <img src={`${process.env.REACT_APP_IMG_URL}lasertherapy_block2_img.webp`} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="laser_block3">
        <div className="right_image_and_text">
          <div className="sky_text_background block-padding">
            <div className="common_width">
              <div className="text_position">
                <div className="textwrapper blue-title-black-description">
                  <h2>Common Conditions Class IV Laser Treats:</h2>
                  <h6>
                    Class IV laser therapy helps with a variety of health and
                    pain conditions such as:
                  </h6>

                  <div className="block_listwrapper colun_count2">
                    <p>
                      General chronic pain from an injury, accident, or disease
                    </p>
                    <p>Sprains and strains</p>
                    <p>Carpal tunnel syndrome</p>
                    <p>Nerve damage and pain</p>
                    <p>Fractures or aches from healing bones</p>
                    <p>Osteoarthritis and other forms of arthritis</p>
                    <p>Back or neck pain (whiplash from a car accident)</p>
                    <p>Nerve damage</p>
                    <p>Extremity joint pain</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="laser_block4">
        <div className="left_image_and_text">
          <div className="blue_text_background block-padding">
            <div className="common_width">
              <div className="text_position">
                <div className="textwrapper white-title-white-description">
                  <h2>Benefits of Class IV Laser Therapy</h2>
                  <div className="block_listwrapper colun_count2">
                    <p>Reduces inflammation </p>
                    <p>Cellular regrowth and production </p>
                    <p>Heals injuries </p>
                    <p>Improves vascular activity</p>
                    <p>Stimulates nerve function</p>
                    <p>Scar Tissue Reduction </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LaserTherapy;
