import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import store from "../../store";


export const getPostList = createAsyncThunk("post/list", async (reqbody) => {

    const accessToken = (store.getState().loginSlice?.token); /// access token
    const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token
    console.log("reqbody==>", reqbody);
    if (reqbody == null && reqbody == undefined) {
        reqbody = {
            "source": "social_advs",
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "type": "desc",
                "field": "createdon_datetime"
            },
            'token': accessToken,
            'email': userMail,
            "searchcondition": {},
           
            

           
        }
    }

    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "social/list-data", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

/*************************************Edit Data for post management********************* */
export const postEditData = createAsyncThunk("postEditData",
    async (id) => {
        let reqBody = {
            '_id': id,
           
        }
        let reqbody = {}
        reqbody = { ...reqBody }
        const requestOptions = {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(reqbody)
        };
        const response = await fetch(process.env.REACT_APP_API_URL + "social/fetch-single-data", requestOptions);
        const respdata = await response.json();
        return respdata;
    }
)


export const postStausUpdate = createAsyncThunk(
    "post/status",
    async (reqbody) => {
      let req_body = {};
      req_body = { ...reqbody };
      console.log("req_body==", req_body);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req_body),
      };
      console.log("process.env==>", process.env);
      const responseData = await fetch(
        process.env.REACT_APP_API_URL + "social/update-status",
        requestOptions
      );
      const respData = await responseData.json();
      return respData;
    }
  );

  export const getPostSearchList = createAsyncThunk("post/search", async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const responseData = await fetch(process.env.REACT_APP_API_URL + "social/list-data", requestOptions);
    const responseCount = await fetch(process.env.REACT_APP_API_URL + "social/list-count-data", requestOptions);
    const respData = await responseData.json();
    const respCount = await responseCount.json();
    console.log("respdata after endpoint fetch==>", respData);
    return {respData,respCount};
});

export const postPreviewData = createAsyncThunk("postPreviewData", async (reqBody) => {
    // console.warn("<<<<<< inside fetchSingleData >>>>", reqBody)

    const reqOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ _id: reqBody, "source": "social_advs" })
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "social/fetch-single-data", reqOption);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


export const getTagData = createAsyncThunk("getagdata", async () => {

    // console.log("reqbody==>", reqbody);
    let req_body = {
        "source": "social_tags",
        "sort": {
            "type": "desc",
            "field": "_id"
        },
        "searchcondition": {
            
        }

        
    };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    // console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "social/autocompletetags", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;

});


  


  


const postManagement = createSlice({
    name: "postManagement",
    initialState: {
        submitting: false,
        loading: false,
        submitted: false,
        // message: null,
        postList: [],
        editPost: [],
        editloading: false,
        statusChangeFlag: null,
        previewData:[],
        previewloading:false,
        gettag:[],
        postListCount: [],
      
        // loadMore: false,
        // viewLess: false,
        // successmsg: "",
        // success: false,
        // currentEditUserData: null,
        // statusUpdateFlage: null,
        // maltiDeleteFlage: null,
        // editDataforTestimonial: {}
    },

    reducers: {

        setPostEditData(state, action) {
            console.log("state from seteditdata==>", state, action);
            if (action.payload !== undefined && action.payload != null) {
              state.editPost = action.payload;
            }
          },

        clearPostEdit (state, action)  {
            state.editPost = [];
        },

        clearPostList(state, action) {
            // console.log('Action of clearVideoGalList ===>', action);
            if (action.payload && (action.payload !== undefined)) {
               state.postList = [];
            }
        },
        clearstatusChangeFlagPost(state, action) {
            state.statusChangeFlag = null;
          },
      

       


    },

    extraReducers: {

        //-----------------------Post Management LISTING------------------//

        [getPostList.pending]: (state, action) => {
            state.loading = true;
        },
        
        [getPostList.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {

                console.log("state=============",action.payload.status)

                state.postList = action.payload.results.res;

                console.log("state...............",action.payload.results.res)

                state.loading = false;



            }
        },

        [getPostList.rejected]: (state, action) => {
            state.loading = false;
        },

        /*************************Post Edit************************** */
        [postEditData.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
        },
        [postEditData.pending]: (state, action) => {
            state.editloading = false
        },
        [postEditData.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.editPost = action.payload.results.res[0];
                state.editloading = false;
            }
        },

         //////////////////////////////////status update///////////////////////////////////
    [postStausUpdate.pending]: (state, action) => {
        state.loading = true;
        state.statusChangeFlag = true;
      },
      [postStausUpdate.fulfilled]: (state, action) => {
        console.log("action", action);
        if (action.payload.status == "success") {
          state.loading = false;
          state.submitting = false;
          state.statusChangeFlag = false;
          // state.message = action.payload.message;
          // state.snackbar = true;
        }
      },
      [postStausUpdate.rejected]: (state, action) => {
        state.loading = false;
        state.statusChangeFlag = false;
      },

      /*************************Post Preview************************** */
      [postPreviewData.rejected]: (state, action) => {
        state.endpointsuccess[action.type] = false
    },
    [postPreviewData.pending]: (state, action) => {
        state.previewloading = false
    },
    [postPreviewData.fulfilled]: (state, action) => {
        if (action.payload.status === 'success') {
            state.previewData = action.payload.results.res;
          
        }
    },

    /*****tag auto complete data*** */
    [getTagData.rejected]: (state, action) => {
        state.loading = false;
        state.successAction = false;

    },
    [getTagData.pending]: (state, action) => {
        // console.log("action p", action)
        // state.usersList = [];
        state.loading = true;
    },
    [getTagData.fulfilled]: (state, action) => {
        console.log("action f storedata", action)
        if (action.payload.status === 'success') {
            console.log("Resyyyyyyyyyyyyyyyyyyy",action.payload.results.res)
            state.gettag = action.payload.results.res;

        }
    },

     //-----------------------Post List Search----------------------------
     [getPostSearchList.pending]: (state, action) => {
        state.loading = true;
        // state.locationData = null;
        state.message = null;
    },
    [getPostSearchList.fulfilled]: (state, action) => {
        state.loading = false;
        if (action.payload.status === 'success' && action.payload.respCount.status === 'success') {
            state.postList = action.payload.respData.results.res;
            state.postListCount = action.payload.respCount.count;
            state.message = action.payload.respData.status;
        }
    },
    [getPostSearchList.rejected]: (state, action) => {
        state.loading = false;
    },


    }


})


export default postManagement.reducer;
export const { clearPostEdit, setPostEditData,clearstatusChangeFlagPost } = postManagement.actions;