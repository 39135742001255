import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import store from "../../store";

// *************************Tag Management List Data**********************
export const getTagList = createAsyncThunk("tag/list", async (reqbody) => {
    // const accessToken = (store.getState().loginSlice?.token); /// access token
    // const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token
    console.log("reqbody==>", reqbody);
    if (reqbody == null && reqbody == undefined) {
        reqbody = {
            "source": "social_tags",
            "condition": {
                "limit": 10,
                "skip": 0
            },
            "sort": {
                "type": "desc",
                "field": "_id"
            },
            // "count": false,
            "searchcondition": {}
        }
    }
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "social/taglist", requestOptions);
    const respdata = await response.json();
    console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

export const getTagSearchList = createAsyncThunk("tag/search/list", async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const responseData = await fetch(process.env.REACT_APP_API_URL + "social/taglist", requestOptions);
    const responseCount = await fetch(process.env.REACT_APP_API_URL + "social/taglist-count", requestOptions);
    const respData = await responseData.json();
    const respCount = await responseCount.json();
    // console.log("respdata after endpoint fetch==>", respData);
    return {respData,respCount};
});
// **********************Edit Data for Tag Management******************
export const tagEditData = createAsyncThunk("tagEditData",
    async (id) => {
        let reqBody = {
            '_id': id,
        }
        let reqbody = {}
        reqbody = { ...reqBody }
        const requestOptions = {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(reqbody)
        };
        const response = await fetch(process.env.REACT_APP_API_URL + "social/fetchsingletag", requestOptions);
        const respdata = await response.json();
        return respdata;
    }
)

const tagManagement = createSlice({
    name: "tagManagement",
    initialState: {
        editTag: [],
        tagListData: [],
        tagListCount: [],
    },
    reducers: {
        cleartageditdata(state, action) {
            state.editTag = [];
        },
        clearTagList(state, action) {
                state.tagListData = [];
        },
    },
    extraReducers: {
        //-----------------------Tag Management Linsting------------------//
        [getTagList.pending]: (state, action) => {
            state.loading = true;
        },
        [getTagList.fulfilled]: (state, action) => {
            if (action.payload.status === "success") {
                // console.log("state=============", action.payload.status)
                state.tagListData = action.payload.results.res;
                console.log("state...............", action.payload.results.res)
                state.loading = false;
            }
        },
        [getTagList.rejected]: (state, action) => {
            state.loading = false;
        },
        //-----------------------Tag List Search----------------------------
        [getTagSearchList.pending]: (state, action) => {
            state.loading = true;
            // state.locationData = null;
            state.message = null;
        },
        [getTagSearchList.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.respData.status === 'success' && action.payload.respCount.status === 'success') {
                state.tagListData = action.payload.respData.results.res;
                state.tagListCount = action.payload.respCount.count;
                state.message = action.payload.respData.status;
            }
        },
        [getTagSearchList.rejected]: (state, action) => {
            state.loading = false;
        },

        /*************************Tag Edit************************** */
        [tagEditData.rejected]: (state, action) => {
            state.endpointsuccess[action.type] = false
        },
        [tagEditData.pending]: (state, action) => {
            state.editloading = false
        },
        [tagEditData.fulfilled]: (state, action) => {
            if (action.payload.status === 'success') {
                state.editTag = action.payload.results[0];
                // state.editloading = false;
            }
        },
    }
})
export default tagManagement.reducer;
export const { cleartageditdata, clearTagList } = tagManagement.actions;