import { configureStore } from "@reduxjs/toolkit";
 
import formSlice from './form/formReducer';
import tableSlice from './listing/listReducer';
import blogSlice from "./Pages/BlogBackend/BlogReducer";
import loginSlice from './Pages/Login/loginReducer';
import layoutSlice from './Layout/layoutReducer';
import teamManagementSlice from './Pages/TeamManagementBackend/teamManagementReducer';
import fileUploaderSlice from "./form/fileUploaderReducer";
import userDashboardKey from './Pages/AdminDashboardBackend/adminDashboardReducer';
import testimonialReducer from "./Pages/TestimonialBackend/testimonialReducer";
import imagegallerySlice from "./Pages/GalleryBackend/ImageGalleryBackend/ImageGalleryReducer";
import videogallerySlice from "./Pages/GalleryBackend/VideoGalleryBackend/VideoGalleryReducer";
import locationManagementReducer from './Pages/LocationManagementBackend/LocationManagementReducer';
import socialMediaAdvoSlice from "./Pages/SocialMediaAdvoBackend/socialMediaAdvoReducer";
import bannerSlice from './Pages/BannerManagement/BannerReducer'
import accountSlice from './Pages/MyAccount/AccountReducer'
import searchReducer from "./search/searchReducer";
import postManagement from "./Pages/PostManagement/PostManagementReducer";
import ResolveReducer from "../src/helper/ResolveReducer"
import tagManagement from "../src/Pages/TagManagement/TagManagementReducer"



export default configureStore({
  reducer: {
    loginSlice,
    formSlice,
    accountSlice,
    tableSlice,
    blogSlice,
    layoutSlice,
    searchSlice: searchReducer,
    teamManagementSlice,
    fileUploaderSlice,
    userDashboardKey,
    tesTimonialKey: testimonialReducer,
    imagegallerySlice,
    videogallerySlice,
    locationManagementReducer,
    socialMediaAdvoSlice,
    bannerSlice,
    postManagement,
    ResolveReducer: ResolveReducer,
    tagManagement,
    
  },
});