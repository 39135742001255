import React from "react";
import { useLocation } from "react-router-dom";
import "../../index.css";
import "../ElectricCellTreatment/ElectricCellTreatment.css";

function ElectricCellTreatment() {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  console.log("location ====", splitLocation);

  return (
    <>
      <div className="electric_cell_banner common_banner_sec">
        <div className="banner_body">
          <div className="banner_wrapper">
            <div className="banner_wrpr_bg">
              <div className="service_banner_con">
                <div className="common_width">
                  <div className="banner_con_text">
                    <div className="banner_con_text1  font55">
                      <h1>Electric Cell Signalling Treatment</h1>
                      <h5>
                        Call us <a className="banner_link" href="tel:(951) 808-5221">(951) 808-5221</a>{" "}
                        <span>
                          Visit us at 802 Magnolia Ave Ste 103 Corona, CA 92879
                        </span>
                      </h5>
                      <a
                        href="https://qualitypracticeweb.com/inlandlight"  target={"_blank"}
                        className="green_button"
                      >
                        <h6>Request an appointment here</h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="electric_cell_block1">
        <div className="left_image_and_text">
          <div className="blue_text_background  block-padding">
            <div className="common_width">
              <div className="text_position">
                <div className="textwrapper  white-title-white-description">
                  <h2>
                    Electric Cell Signalling Treatment in Corona, CA with Dr.
                    Robert S. Krage D.C.
                  </h2>
                  <p>
                    The RST Sanexas neoGEN® is the device that performs the
                    electrotherapy treatment, it uses electronic signal energy
                    waves produced by an ultra-high digital frequency generator.
                    These therapeutic waves stimulate the nerves on a cellular
                    level without being invasive or causing damage.
                    Electrotherapy treatments, over a long period of time, many
                    patients report that they have an increase in mobility, less
                    reliant on pain medication, and an overall improvement in
                    their life.
                  </p>
                  <p>
                    The electrotherapy treatments are performed with sponges
                    soaked in water attached to electrodes that transfer the
                    electric signals to the area of treatment, the wavelengths
                    vary between low and middle frequency signals (AM and FM
                    energy waves). The benefit of this therapy is that it has
                    the ability to affect your nerve cell membranes and the
                    surrounding tissue to produce a harmonic resonant vibration.
                  </p>
                  <p>
                    When nerves are damaged or swollen, they take on different
                    frequencies than other nerves. Electric cell Signalling
                    tunes them back to the correct frequency, which reduces the
                    pain frequency sent to the brain. This device helps soothe
                    inflamed nerves by utilizing energy waves.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`greentitlebar ${splitLocation[1] === "" ? "hidebar" : ""}`}
      >
        <h2>
          Come in Right Away For Our <span>HEALTH RISK EXAM!</span>
        </h2>
        <div className="greentitle_button_wrapper">
          <button>
            <a href="https://qualitypracticeweb.com/inlandlight"  target={"_blank"}>Click Here</a>
          </button>
        </div>
      </div>

      <div className="electric_cell_block2 block-padding">
        <div className="common_width">
          <div className="flex_wrapper">
            <div className="textwrapper blue-title-black-description">
              <h2>What Conditions Does Electrotherapy Treat?</h2>
              <div className="block_listwrapper colun_count2">
                <p>
                  Management and relief of chronic long-term pain (neuropathy)
                </p>
                <p>Treatment of pain syndromes</p>
                <p>Treatment in post-surgical pain conditions</p>
                <p>Relaxation of muscle spasms</p>
                <p>Neuromuscular reeducation</p>
                <p>Reduction of disuse atrophy </p>
                <p>Maintaining and increasing range of motion</p>
                <p>Increasing local circulation</p>
                <p>
                  Immediate post-surgical circulation of calf muscles to prevent
                  phlebothrombosis
                </p>
              </div>
            </div>

            <div className="image_wrapper image_wrapper_padding">
              <div className="image_inner_wrapper">
                <img
                  src={`${process.env.REACT_APP_IMG_URL}IEIM_electric_cell_block2_img.webp`}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="electric_cell_block3">
        <div className="left_image_and_text">
          <div className="blue_text_background block-padding">
            <div className="common_width">
              <div className="text_position">
                <div className="textwrapper  white-title-white-description">
                  <h2>Benefits of Electric Cell Signalling Treatment:</h2>
                  <div className="block_listwrapper colun_count2">
                    <p>Non-Invasive </p>
                    <p>Non-Pharmaceutical </p>
                    <p>Safe </p>
                    <p>Clinically proven to be effective</p>
                    <p>High satisfaction for patients </p>
                    <p>Heals damaged nerves</p>
                    <p>Greatly reduces pain</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ElectricCellTreatment;
