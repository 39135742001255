import React, { useEffect, useRef, useState } from "react";
import '../Testimonials/Testimonials.css'
import { Button, IconButton, LinearProgress, Menu, MenuItem, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { clearTestimonialList, getTestimonialList } from "../../Pages/TestimonialBackend/testimonialReducer";
import { useDispatch, useSelector } from "react-redux";
import { getEventData, randomId } from "../../helper/helperFunctions";
import { useLocation, useNavigate } from "react-router-dom";

const reqBody = {
  condition: {
    limit: 6,
    skip: 0,
  },
  searchcondition: { status: 1 },
  sort: { type: "asc", field: "priority" },
  source: "testimonial_views",
  projection: {
    image: 1,
    title: 1,
    feedback: 1,
    patient_name: 1,
    createdon_datetime: 1,
    rating: 1,
    video: 1,
    youtubeVideo: 1,
    audio_exists: 1,
  },
  relation: 'Frontend'
};


function Testimonials() {


  // ---------------------------------------------Invoking Hooks--------------------------------------------- //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollToTestimonial = useRef();

  // ----------------------------------------Global State from Redux---------------------------------------- //
  const logedinUserInfo = useSelector((state) => state.loginSlice.userInfo ? state.loginSlice.userInfo : null);
  const resolved = useSelector(state => state.layoutSlice.resolved['/testimonials'] ? state.layoutSlice.resolved['/testimonials'] : false);//for resolve
  const tesTimonialArry = useSelector(state => (state.tesTimonialKey?.tesTimonialList) ? state.tesTimonialKey.tesTimonialList : []);
  const loading = useSelector(state => state.tesTimonialKey?.loading);

  // ----------------------------------------Components Private State---------------------------------------- //
  const [anchorEl, setAnchorEl] = useState(null);

  // --------------------------------------------Other Veriables-------------------------------------------- //
  const open = Boolean(anchorEl);

  // -------------------------------------------useEffect Section------------------------------------------- //
  useEffect(() => {
    // console.log('Getting testimonial frontend ---> useEffect 1');
    if (!resolved || tesTimonialArry.length === 0) {
      dispatch(clearTestimonialList('Frontend List'));
      dispatch(getTestimonialList(reqBody));
    };
    return () => dispatch(clearTestimonialList('Frontend List'));
  }, []);

  // For auto scroll to blog in the blog page
  useEffect(() => scrollToTestimonial.current.scrollIntoView({ behavior: "smooth" }), [scrollToTestimonial]);

  // For perform action on element event
  useEffect(() => {
    let resolveData = {
      path: "/frontend/testimonial-details",
      resolveFunc: [getTestimonialList],
      body: { ...reqBody, relation: 'Frontend Details' },
    };

    getEventData(tesTimonialArry, navigate, 'testimonial-list', 'testimonial-list-event', 'elem-event-data', '/frontend/testimonial-details/', null, '/frontend/testimonial-details', resolveData, dispatch)
  }, [tesTimonialArry]);

  // ---------------------------------------------Other Handelers--------------------------------------------- //
  const handleClick = (event) => {
    console.log("event -------->", event);
    if (logedinUserInfo && Object.keys(logedinUserInfo).length == 0) setAnchorEl(event.currentTarget);
    else handleClose(`/frontend/testimonials/add/${logedinUserInfo._id}`)
  }
  const handleClose = (path) => {
    setAnchorEl(null);
    navigate(path);
  };

  //----------------------------------------------Functions for returning jsx-----------------------------------------------//
  const getTestiList = () => {
    const newArrayOfObj = tesTimonialArry.map(({
      image: images,
      feedback: feedBack,
      patient_name: patientName,
      createdon_datetime: creationTime,
      video: videos,
      youtubeVideo: youtubeVideos,
      audio_exists: audios,
      ...rest
    }) => ({
      images,
      loaderPath: "https://all-frontend-assets.s3.amazonaws.com/inland-empire-integrated-medicine-images/imgpsh_fullsize_anim-min.jpeg",
      feedBack,
      patientName,
      creationTime,
      videos,
      youtubeVideos,
      audios,
      shareLinks: ["Facebook", "Twitter", "Linkedin"],
      ...rest
    }));

    let testiList = {
      projectUrl: process.env.REACT_APP_API_EXTERNAL_URL,
      socialSharePath: 'frontend/testimonial-details',
      loaderPath: "https://all-frontend-assets.s3.amazonaws.com/inland-empire-integrated-medicine-images/imgpsh_fullsize_anim-min.jpeg",
      shareLinks: ["Facebook", "Twitter", "Linkedin"],
      initialArr: newArrayOfObj,
      apiCall: {
        apiUrl: `${process.env.REACT_APP_API_URL}/api1/testimoniallistingusers`,
        body: {
          condition: {
            limit: 6,
            skip: 0,
          },
          searchcondition: { status: 1 },
          sort: { type: "asc", field: "priority" },
          projection: {
            image: 1,
            title: 1,
            feedback: 1,
            patient_name: 1,
            createdon_datetime: 1,
            rating: 1,
            video: 1,
            youtubeVideo: 1,
            audio_exists: 1,
          },
          relation: 'Frontend',
          source: "testimonial_views",
        }
      },
    }
    return (<testimonial-list key={randomId} data={JSON.stringify(testiList)} />)
  }






  return (
    <div>

      <div className='testimonials_banner'>

        <img src='https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/health+and+pain+care-TESTIMONIALS-+banner_image.webp' />
      </div>



      <div className="testimonials_section">
        <div className="testimonials_block">
          <div className="common_width">
            <h2 className="testimonials_heading" ref={scrollToTestimonial}>Testimonials </h2>
            {loading && loading ? <div><LinearProgress className='' /></div> : null}

            <div className="add_testimonial_btnwrapper">
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                Add Testimonial
              </Button>
              {logedinUserInfo && Object.keys(logedinUserInfo).length === 0 &&
                <Menu
                  id="basic-menu"
                  className="add_testimonial_menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={() => handleClose('/frontend/testimonials/add/guest')}>Add as a Guest</MenuItem>
                  <MenuItem onClick={() => handleClose(`/login`)}>Login to Add</MenuItem>
                </Menu>}
            </div>
            <div className="testilist_wrpr">
              {/* <------------------------------Testimonial Card List From angular element------------------------------> */}
              {tesTimonialArry && tesTimonialArry.length > 0 ?
                getTestiList() : <div className='noDataAvailable' ><img src="https://all-frontend-assets.s3.amazonaws.com/Galveston/assets/images/no_result_image.png" /></div>
              }
            </div>
          </div>
        </div>

      </div>





    </div>

  )
}

export default Testimonials
