import React, { useEffect, useRef, useState } from "react";
// import "../../../../src/index2.css";
import '../Testimonials/Testimonials.css'
import { Button, LinearProgress, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { clearTestimonialDetails, getTestimonialList } from '../../Pages/TestimonialBackend/testimonialReducer';
import DescriptionModification from "../Blog/DescriptionModification";
import HelpIcon from '@mui/icons-material/Help'
import AddIcon from '@mui/icons-material/Add';


const reqBody = {
    condition: {
        limit: 6,
        skip: 0,
    },
    searchcondition: { "status": 1 },
    sort: { type: "asc", field: "priority" },
    source: "testimonial_views",
    projection: {
        title: 1,
        image: 1,
        feedback: 1,
        video: 1,
        youtubeVideo: 1,
        audio_exists: 1,
        patient_name: 1,
        rating: 1,
        createdon_datetime: 1,
    },
    relation: 'Frontend Details'
};

const TestimonialDetails = () => {
    // ---------------------------------------------Invoking Hooks--------------------------------------------- //
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const pathParam = useParams();
    const { pathname } = useLocation();
    const scrollToTestimonial = useRef();

    const { _id } = useParams();
    console.log("_id======>",_id)


    const [openDescriptionModificationModal, setDescriptionModificationModal] =useState(false); //for Description Modification for Meta

  const formSubmissionState = useSelector(state => state.formSlice.formSubmissionState.descriptionModification);

  const userType = useSelector((state)=>state.loginSlice.userInfo.type === "admin")


    // ----------------------------------------Global State from Redux---------------------------------------- //
    const resolved = useSelector((state) => state.layoutSlice.resolved["/testimonial-details"] ? state.layoutSlice.resolved["/testimonial-details"] : false); //for resolve
    const { testimonialArr, singleTestimonial } = useSelector(state => (state.tesTimonialKey?.tesTimonialDetail) ? state.tesTimonialKey.tesTimonialDetail : {});
    const loading = useSelector((state) => state.tesTimonialKey?.loading);

    // ----------------------------------------Components Private State---------------------------------------- //
    const [testimonialDetail, setTestimonialDetail] = useState(null);

    // --------------------------------------------Other Veriables-------------------------------------------- //
    const pathWithoutParam = pathname.slice(1, pathname.lastIndexOf('/'));

    // --------------------------------------------useEffect Section-------------------------------------------- //
    useEffect(() => {
        if (!resolved && testimonialArr.length === 0) {
        dispatch(clearTestimonialDetails("Frontend Details"));
        dispatch(getTestimonialList({ ...reqBody, searchcondition: { ...reqBody.searchcondition, _id: { $ne: pathParam._id } } }));
        }
        return () => dispatch(clearTestimonialDetails("Frontend Details"));
    }, [])

    // For auto scroll to blog in the blog page
    useEffect(() => scrollToTestimonial.current.scrollIntoView({ behavior: "smooth" }), [scrollToTestimonial]);

    // For setting 'testimonial-detail' element data
    useEffect(() => {


        let newArrayOfObj = [];
        if (testimonialArr && testimonialArr.length > 0) {
            newArrayOfObj = testimonialArr.map(({
                image: images,
                feedback: feedBack,
                video: videos,
                youtubeVideo: youtubeVideos,
                audio_exists: audios,
                patient_name: patientName,
                createdon_datetime: creationTime,
                ...rest
            }) => ({
                images,
                loaderPath: "https://all-frontend-assets.s3.amazonaws.com/inland-empire-integrated-medicine-images/imgpsh_fullsize_anim-min.jpeg",
                feedBack,
                videos,
                youtubeVideos,
                audios,
                patientName,
                creationTime,
                shareLinks: ["Facebook", "Twitter", "Linkedin"],
                ...rest
            }));
        }

        if (singleTestimonial) {
            setTestimonialDetail({
                projectUrl: process.env.REACT_APP_API_EXTERNAL_URL,
                socialSharePath: pathWithoutParam,
                apiCall: {
                    apiUrl: `${process.env.REACT_APP_API_URL}/api1/testimoniallistingusers`,
                    body: { ...reqBody, searchcondition: { status: 1, _id: { $ne: singleTestimonial._id } } },
                },
                testimonialList: newArrayOfObj,
                _id: pathParam._id,
                title: singleTestimonial?.title,
                imgUrl: singleTestimonial?.image[0]?.url,
                loaderPath: "https://all-frontend-assets.s3.amazonaws.com/inland-empire-integrated-medicine-images/imgpsh_fullsize_anim-min.jpeg",
                feedBack: singleTestimonial?.feedback,
                videos: singleTestimonial?.video ? singleTestimonial.video : null,
                youtubeVideos: singleTestimonial?.youtubeVideo ? singleTestimonial?.youtubeVideo : null,
                audios: singleTestimonial?.audio_exists,
                patientName: singleTestimonial?.patient_name,
                rating: singleTestimonial?.rating,
                creationTime: singleTestimonial?.createdon_datetime,
                shareLinks: ["Facebook", "Twitter", "Linkedin"]
            });
        }
    }, [testimonialArr, singleTestimonial]);

    // For perform action on element event
    // useEffect(() => getEventData(testimonialArr, navigate, 'testimonial-detail', 'testimonial-detail-event', 'elem-event-data', '/frontend/testimonial-details/', null, '/frontend/testimonial-details'), [testimonialArr]);

    useEffect(() => {
        // console.log("singleTestimonial ------------>", singleTestimonial);
        let domNodeArr = null;
        setTimeout(() => {
            domNodeArr = document.querySelectorAll('testimonial-detail');
            if (domNodeArr && domNodeArr.length > 0) {
                for (let i = 0; i < domNodeArr.length; i++) {
                    if (domNodeArr[i]) domNodeArr[i].addEventListener('testimonial-detail-event', (e) => {
                        let eventData = JSON.parse(e.target.getAttribute('elem-event-data'));
                        switch (eventData.type) {
                            case "read_more": {
                                navigate(`/frontend/testimonial-details/${eventData._id}`);
                                window.location.reload();
                            }
                                break;
                            case "shareInFacebook": window.open(`https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_API_EXTERNAL_URL}${pathWithoutParam}/${eventData._id}`, "", "width=500,height=300");
                                break;
                            case "shareInTwitter": window.open(`https://twitter.com/intent/tweet?url=${process.env.REACT_APP_API_EXTERNAL_URL}${pathWithoutParam}/${eventData._id}`, "", "width=500,height=300");
                                break;
                            case "shareInLinkedin": window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${process.env.REACT_APP_API_EXTERNAL_URL}${pathWithoutParam}/${eventData._id}`, "", "width=500,height=300");
                                break;
                            default: console.log("Inside Default Case of switch-case");
                                break;
                        }
                    });
                }
            }
        }, 1000);

    }, [testimonialArr]);
    

    const handleOpenDescriptionModification = () => {
        setDescriptionModificationModal(true);
      };
      const handleCloseDescriptionModification = () => {
        setDescriptionModificationModal(false);
      };



  useEffect(() => {
   
    if (formSubmissionState && formSubmissionState == 2) {
      setDescriptionModificationModal(false);
    
      
    }
  }, [formSubmissionState]);
    //----------------------------------------------return-----------------------------------------------//
    return (

        <>

            <div>
                <div className='testimonials_banner'>

                    <img src='https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/health+and+pain+care-TESTIMONIALS-+banner_image.webp' />
                </div>


                <div className="testimonials_section">
                    <div className="testimonials_block">
                        <div className="common_width">
                            <h2 className="testimonials_heading" ref={scrollToTestimonial}>Check out our latest uploaded Testimonials of Inland Empire Integrated Medicine </h2>

                           {userType && <div className="add_testimonial_btnwrapper">
                            <Button className="testimonial_details"    onClick={() => handleOpenDescriptionModification()}>Coustom Meta</Button>
          
          </div>}



                            {loading && loading ? <div><LinearProgress className='' /></div> : null}

                            <div className="testilist_wrpr testi_detail_wrpr">
                                {/* <------------------------------Testimonial Detail From angular element------------------------------> */}
                                {testimonialDetail ? <testimonial-detail data={JSON.stringify(testimonialDetail)}></testimonial-detail> : null}
                            </div>
                        </div>
                    </div>
                </div>

                <DescriptionModification
        open={openDescriptionModificationModal}
        close={handleCloseDescriptionModification}
        detailid={_id}
        dynamicEndPoint= "api1/update-custom-meta-title"
        source="testimonial_schemas"
        />

            </div>
        </>








    )
}

export default TestimonialDetails