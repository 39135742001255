import React from 'react'
import "../../../index.css"
import "../AdvancedBiologics.css"

function AdvancedBio2() {
  return (
    <div>
    <div className="pain_management_block3 SD_block6">
        <div className="right_image_and_text">
          <div className="sky_text_background block-padding">
            <div className="common_width">
              <div className="text_position">
                <div className="textwrapper blue-title-black-description">
                  <h2>
                  Amnion provides ideal regenerative 
                  scaffold for cell proliferation 
                  and differentiation
                  {" "}
                  </h2>
                  <p><span className='bold_span_b6'>
                  Unique Healing Properties of Amnion<br/>
                  Scarless fetal wound healing: A basic science review<br/>
                  Barrett J. Larson, B. S., Michael T. Longaker, M.D., M.B.A., and H. Peter Lorenz, M.D. Hagey Laboratory for pediatric regenerative medicine, division of plastic surgery, department of surgery, Stanford university school of medicine

                  </span></p>
                  <p>
                  Summary<br/>
                  Scar formation is a major medical problem that can have devastating 
                  consequences for patients. The adverse physiological effects of scars are broad, and there are currently no reliable treatments to prevent scarring. In contrast to adult wound, early gestation fetal skin wound repair rapidly and in the absence of scar formation. Despite extensive investigation, the exact mechanisms of scarless fetal wound healing remain largely unknown. For some time, it has been known that significant differences exist among the extracellular matrix, inflammatory response, cellular mediators, and gene expression profiles of fetal and postnatal wound. These differences may have important implications in scarless wound repair.
                  {" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    <div className="healthrisk_block1 SD_block7">
    <div className="left_image_and_text">
    <img className='advance_img_abs' src="https://all-frontend-assets.s3.amazonaws.com/IEIM/assets/IEIM_Advance_bio_block7_Logo.png"/>
      <div className="blue_text_background block-padding">
        <div className="common_width">
          <div className="text_position">
            <div className="textwrapper white-title-white-description">
              <h2>XWARP</h2>
              <p>
              <span> XWRAP®</span> is a regenerative, anti-adhesive, anti-inflammatory, and anti-microbial amnion derived biological used to repair DFUs, VLUs, and Pressure Ulcers.
              </p>
              <p>
              <span> XWRAP®</span> is intended for, but not limited to use for chronic wound repair.
              </p>
              <p>
              <span> XWRAP®</span> is the intermediate amniotic epithelial layer of the placenta. This layer is a rich source of epidermal growth factor(EGF), fibroblast growth factor (FGF), platelet derived growth factor (PDGF), and vascular endothelial growth factor (VEGF), which stimulate angiogenesis; Tissue inhibitors of metalloproteinases (TIMPs), which inhibit metalloproteinase activity and matrix degradation; Extracellular matrix proteins such as fibronectins and laminins, which drive cell attachment, migration, and subsequent organization; as well as Type I, II, III, V, and VI collagen.
              </p>
              <p>
              <span> XWRAP®</span> is recovered during a Cesarean deliveryfrom healthy, pre-screened donors, and is processed to preserve the structural integrity ofthe amniotic epithelial membrane. A Clinical Laboratory Improvement Amendment(CLIA) certified lab that meets or exceeds the American Association of Tissue Banks (AATB) criteria screens and tests XWRAP® to minimize the risk of communicable diseases. All donors’ medical history and behavior is assessed and evaluated 
              following United States Public Health Service (USPHS) guidelines.
              </p>
              <p>
              <span> XWRAP®</span> is an allograft tissue that is regulated under section 361 of the PHS Act and meets the criteria of Title 21 Code for Federal 
              Regulation (CFR) 1271.3 and 1271.10. From tissue recovery, tissue 
              processing, and packaging, Applied Biologics ensures that each unit of XWRAP®               has been carefully screened and meets industry 
              standards for the use of biologic products in urgery.
              </p>
              <p>
              <span>Applications</span><br />
              XWRAP® is an amniotic membrane that can be used to repair DFUs, 
              VLUs and Pressure Ulcers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
      
    </div>
  )
}

export default AdvancedBio2
