import { Alert, LinearProgress, Snackbar } from '@mui/material'
import React from 'react'
import { Form } from '../../form/Form'
import "../Contact/Contact.css"
import state from '../../stateJason/states'
import { resetForm } from '../../form/formReducer'
import store from '../../store'
import { useDispatch } from 'react-redux';


function Contact() {
    const [loading, setloading] = React.useState(false);
    const [formSubmissionState, setFormSubmissionState] = React.useState(0);
    const [openSnac, setOpenSnac] = React.useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        console.log('formSubmissionState', formSubmissionState, "loading", loading)
        if (formSubmissionState == 1) {
            setloading(true);
        }

        if (formSubmissionState == 2) {
            setloading(false);
            setOpenSnac(true)
            dispatch(resetForm({ formId: "contactForm" }))
        }
    }, [formSubmissionState]);

    store.subscribe(() => {
        const storeData = store.getState();
        setFormSubmissionState(
            storeData.formSlice?.formSubmissionState?.contactForm
        );
    })

    const formData = {
        id: 'contactForm',
        formtype: "add",
        api_url: process.env.REACT_APP_API_URL,
        endPoint: "api2/contact-us",
        endPointBodyWrapper: "data",
        urlPathOnSuccessfulFormSubmission: "/contact",
        msgOnFailedFormSubmission: "Something Went Wrong",
        formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        formInlineCLass: "contactForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        submitBtnName: " Click to submit the form",
        // submitBtnStartIcon: 'image_search_icon',
        // submitBtnEndIcon: 'search_icon',
        resetBtnName: "Reset",
        formAdditionalSubmissionData: {
            type: "admin",
            status: "active",
        },
        fields: [
            //////////////////////////////////// Text Input ///////////////////////////////
            {
                id: 0,
                label: "Name",
                name: "name",
                className: 'Name col2',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    maxLength: "MaxLength length 20 allowed",
                    validate: "Lastnameeeeeeee is not valid",
                    custom: "Value is Invalid"
                },

            },
            {
                id: 1,
                label: "Street Address",
                name: "streetaddress",
                className: 'streetaddress col2',
                type: "text",
                errorMessage: {
                    required: "This Field is Required",
                    maxLength: "MaxLength length 20 allowed",
                    validate: "Lastnameeeeeeee is not valid",
                    custom: "Value is Invalid"
                },

            },
            {
                id: 2,
                label: "City",
                name: "city",
                className: 'City col2',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    maxLength: "MaxLength length 20 allowed",
                    validate: "Lastnameeeeeeee is not valid",
                    custom: "Value is Invalid"
                },

            },
            {
                id: 3,
                // heading: "state",
                label: "State",
                name: "state",
                className: 'year col2',
                type: 'select',
                // multiple: true,
                // checkbox: true,
                other: true,
                rules: { required: true },
                values: state,
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                // defaultValue: 2024,
                // sx: { m: 1, minWidth: 120 },
            },
            {
                id: 4,
                label: "Zip",
                name: "zip",
                className: 'Zip col2',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    maxLength: "MaxLength length 20 allowed",
                    validate: "Lastnameeeeeeee is not valid",
                    custom: "Value is Invalid"
                },

            },
            {
                id: 5,
                label: "Email Address",
                name: "email",
                className: 'email col2',
                type: "text",
                rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    maxLength: "MaxLength length 20 allowed",
                    validate: "Lastnameeeeeeee is not valid",
                    custom: "Value is Invalid"
                },

            },
            {
                id: 6,
                label: "Phone",
                name: "phone",
                className: 'Phone col2',
                type: "text",
                rules: { required: true },
                inputType: "number",
                errorMessage: {
                    required: "This Field is Required",
                    maxLength: "MaxLength length 20 allowed",
                    validate: "Lastnameeeeeeee is not valid",
                    custom: "Value is Invalid"
                },

            },
            {
                id: 7,
                // heading: "state",
                label: "How did you hear about us?",
                name: "aboutus",
                className: 'year col2',
                type: 'select',
                //  multiple: true,        
                checkbox: true,
                // other: true,
                // rules: { required: true },
                values: [
                    { val: "Search Engine (Google)", name: "Search Engine (Google)" },
                    { val: "Doctor Referral ", name: "Doctor Referral " },
                    { val: "Recommended by family or friends", name: "Recommended by family or friends" },
                    { val: "Social Media", name: "Social Media" },
                    { val: "Blog or Publication", name: "Blog or Publication" },
                    { val: "Print Media", name: "Print Media" },
                    { val: "Other", name: "Other" }

                ],
                errorMessage: {
                    required: "This Field is Required",
                    custom: "Value is Invalid"
                },
                // defaultValue: 2024,
                // sx: { m: 1, minWidth: 120 },
            },
            {
                id: 8,
                type: "html",
                className: "contacttitle",
                value: "<h1>Preferred Contact Method?</h1>"
            },
            {
                id: 9,
                // heading: "Telephone",
                // label: "Telephone",
                name: "telephone",
                className: 'singleCheckbox col2',
                hint: 'In months',
                type: 'singleCheckbox',
                values: { key: 0, val: 'Telephone' },
                errorMessage: {
                    required: "This Field is Required",
                    validate: "is not valid",
                    custom: "Value is Invalid"
                },
                defaultValue: false
            },
            {
                id: 10,
                // heading: "Email",
                // label: "Email",
                name: "emailCheck",
                className: 'singleCheckbox col2',
                hint: 'In months',
                type: 'singleCheckbox',
                values: { key: 1, val: 'Email' },
                errorMessage: {
                    required: "This Field is Required",
                    validate: "is not valid",
                    custom: "Value is Invalid"
                },
                defaultValue: false
            },
            {
                id: 11,
                // heading: "Description",
                name: "description",
                className: 'description col1',
                type: 'textarea',
                label: "Question",
                // placeholder: 'Type Here',
                rows: 4,
                // style: { width: 200 },
                // rules: { required: true, minLength: 20 },
                errorMessage: {
                    required: "This Field is Required",
                    minLength: "Minimum length 20 required",
                    custom: "Value is Invalid"
                },
                // defaultValue: "Test"
            },

            {
                id: 12,
                heading: "12 + 10",
                // label: "Name",
                name: "captcha",
                className: 'captcha',
                type: "text",
                // rules: { required: true },
                errorMessage: {
                    required: "This Field is Required",
                    maxLength: "MaxLength length 20 allowed",
                    validate: "Lastnameeeeeeee is not valid",
                    custom: "Value is Invalid"
                },

            },
        ]
    }


    return (
        <>
            <div className='contact_banner common_banner_sec'>
                <div className='banner_body'>
                    <div className='banner_wrapper'>
                        <div className='banner_wrpr_bg'>
                            <div className='banner_con'>
                                <div className='common_width'>
                                    <div className='banner_con_text'>
                                        <div className='banner_con_text1 font95'>
                                            <h1>Contact Us </h1>
                                            <h5>Look and feel Your Absolute Best</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='contact_block2'>
                <div className='common_width'>
                    <div className='contact_block2_colcontainer'>
                        <div className='contact_block2_left'>
                            <div className='contactblock_imgmainwrapper'>
                                <div className='contactblock_imgsubwrapper'>
                                    <div className='contactblock_imgwrapper'>
                                        <img src={`${process.env.REACT_APP_IMG_URL}contact_image2.png`} />
                                    </div>
                                </div>
                            </div>
                            <div className='textwrapper'>
                                <h2>Call us</h2>
                                <p>Inland Empire Integrated <br /> Medicine 802 Magnolia <br /> Ave Ste 103 <br />
                                    Corona, CA 92879</p>

                                <h3><a href='tel:(951) 808-5221'>(951) 808-5221</a> </h3>
                            </div>
                        </div>
                        <div className='contact_block2_right'>
                            <div className='textwrapper'>
                                <h2>Contact Form</h2>
                                <p>We can’t wait to connect with you.  Complete the form below and someone from our office will be reaching out to you shortly to answer any questions that you may have.</p>
                            </div>
                            <Form formData={formData} />
                            {loading && (
                                <LinearProgress />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Snackbar
                open={openSnac}
                onClose={() => setOpenSnac(false)}
                autoHideDuration={3000}
            >
                <Alert
                    severity="success"
                    sx={{ width: "100%" }}
                >
                    <p>
                        Thank You For Connecting With Us.
                    </p>
                    <p>
                        Someone From Our Team Will Connect With You Soon.
                    </p>
                </Alert>
            </Snackbar>
        </>
    )
}

export default Contact
