import React from "react";
import "../../index.css";
import "../Aboutus/Aboutus.css";

function Aboutus() {
  return (
    <>
      <div className="aboutus_banner common_banner_sec">
        <div className="banner_body">
          <div className="banner_wrapper">
            <div className="banner_wrpr_bg">
              <div className="banner_con">
                <div className="common_width">
                  <div className="banner_con_text">
                    <div className="banner_con_text1 font95">
                      <h1>About</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="aboutus_block1">
        <div className="left_image_and_text">
          <div className="blue_text_background block-padding">
            <div className="common_width">
              <div className="text_position">
                <div className="textwrapper white-title-white-description">
                  <h2>Robert S. Krage D.C.</h2>
                  <p>
                    Robert S. Krage D.C. attended post-secondary school at what is now
                    known as Southern California University of Health Sciences,
                    previously known as Los Angeles College of Chiropractic, where
                    he graduated with his chiropractic degree. He enjoys helping
                    people so he made a career out of it. Inland Empire Integrated
                    Medicine has been treating patients for over 20 years, the
                    practice began in 1997. He specializes in orthopedics and foot
                    programs, meaning treatments specifically for plantar
                    fasciitis and neuromas. <br /> Dr. Krage has helped relieve pain for
                    and treated over 4,000 patients!
                  </p>
                  <p>
                    Dr. Robert S. Krage D.C. moved to Corona to start his business; he's been
                    living here ever since. He is a father of 3 and a loving
                    husband. Aside from his chiropractic practice he has many
                    hobbies that fill his time on the weekends, he loves things
                    both indoors and outdoors. He enjoys golfing, camping, and
                    traveling. He also loves spending time with his family and
                    playing the piano. He continually strives to provide the
                    highest quality care to his patients and help improve their
                    lives.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="aboutus_block2">
        <div className="margin_sd">
          <div className="common_width">
            {/* <h1>Rahal Ehsan RN, BSN MSN</h1> */}
            <h2>Education </h2>
            <div className="aboutus_block2_sub_block">
              <div className="aboutus_block2_sub_block_min">
                <div className="b2_min_sub_left">
                  <h3><span>California State University San Marcos</span> San Diego, California</h3>
                  <p>Masters in Nursing Science (MSN) FNP</p>
                </div>
                <div className="b2_min_sub_right align1">
                  <h4>December 2020</h4>
                </div>
              </div>
              <div className="aboutus_block2_sub_block_min">
                <div className="b2_min_sub_left">
                  <h3><span>University of Wyoming,</span> Laramie, Wyoming </h3>
                  <p>Bachelor in Nursing Science (BSN) </p>
                </div>
                <div className="b2_min_sub_right align2">
                  <h4>December 2016</h4>
                </div>
              </div>
              <div className="aboutus_block2_sub_block_min">
                <div className="b2_min_sub_left">
                  <h3><span>Sheridan College,</span> Sheridan, Wyoming</h3>
                  <p>Associate Degree in Nursing (ADN)</p>
                </div>
                <div className="b2_min_sub_right align3">
                  <h4>May 2016</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="aboutus_block3">
        <div className="common_width">
          <div className="aboutus_block3_sub_block">
            <div className="aboutus_block3_sub_block_left">
              <h1>Nurse Practitioner Experience</h1>
              <ul>
                <li>Internal Medicine (Hospitalist) Loma Linda University Murietta. </li>
                <li>Internal Medicine (Adult) training received at IGMA.</li>
                <li>Specialization Training (Adult) in Gastroenterology at IGMA.</li>
                <li> Primary Care (Adult and Pediatric) at CVS Minute Clinic. </li>
                <li>Community Health (Adult and Pediatric) at Care Foundry Clinic. </li>
              </ul>
              <h1>Nurse Practitioner </h1> <h1>Internal Medicine (Hospitalist) </h1>
              <ul>
                <li>He worked at Heart and Vascular Center of Marietta.</li>
                <li>Diagnosed, treated, or provided continuous care for inpatient admissions.</li>
                <li> Prescribed medications and or treatment regimens to hospital inpatients.</li>
                <li>Admitted and conducted discharge planning for inpatient hospital stays.</li>
                <li>Ordered and or interpreted results of tests such as laboratory tests and radiographs (x-rays).</li>
                <li>Analyzed test data or images to inform diagnosis or treatment for referrals of patients to medical specialists, social services or other professionals as deemed appropriate.</li>
                <li>Participated in Rapid Response and Code Blue for management and resolution of acute pathophysiology. </li>
                <li>Communicated with patients and family members regarding plan of care, expected treatments and date of discharges. As considered appropriate, discussion for SNF, LTAC
                  or hospice referral also evaluated.</li>
                <li>Rounded on patients after discharges at SNF or rehabilitation facilities.</li>
              </ul>
            </div>
            <div className="aboutus_block3_sub_block_right">
              <img src={"https://all-frontend-assets.s3.amazonaws.com/IEIM/assets/IEIM_about_block3img.webp"} />
            </div>
          </div>
        </div>
      </div>

      <div className="aboutus_block4">
        <div className="common_width">
          <div className="aboutus_block4_sub_block">
            <div className="block4_wrpr">

              {/* <div className="block4_sub_block_min">
                <div className="b4_sbm_left">
                  <h1>Work Experience</h1>
                  <h2>Loma Linda University Murrietta </h2>
                  <ul>
                    <li>Internal Medicine (Hospitalist) privileged and credentialed with full prescribing authority.</li>
                    <li>Additional privileges and credentialed at Inland Valley/Rancho Springs and at Temecula Valley Hospital. </li>
                  </ul>
                </div>
                <div className="b4_sbm_right">
                  <h3>July 2021-Current</h3>
                </div>
              </div>
              <div className="block4_sub_block_min">
                <div className="b4_sbm_left">
                  <h2>Sumner College (Portland, OR) (Part-Time online Contracted)</h2>
                  <h6>Nurse Educator </h6>
                  <ul>
                    <li>Educated RN students on head-toe and focused physical assessments, neurological assessments, medication
                      administration, pain assessments and documentation. Designed and continually improved/adapt curriculum
                      depending on educational needs.
                    </li>
                  </ul>
                </div>
                <div className="b4_sbm_right">
                  <h3>March 2021 – July 2021 </h3>
                </div>
              </div>
              <div className="block4_sub_block_min">
                <div className="b4_sbm_left">
                  <h2>St. Vincent Medical Center (Los Angeles, CA)</h2>
                  <h2>(Medical-Surgical Unit: Renal/Oncology)  </h2>
                  <h6>Registered Nurse </h6>
                  <ul>
                    <li>Assessments: Performed head to toe and focused physical assessments, neurological assessments- post fall, vital
                      sign assessment pre/post medication administration, pain assessments and documentation- 3 years</li>
                    <li>IV Skills: Started peripheral IV's and safely performed IV push medications in accordance with drug guide,
                      flush central and peripheral IV lines and assessed for patency. Started IV piggyback medications, calculated
                      drip rates and set IV instruments, perform blood draws, and IV site assessment, sterile port access- 3 years</li>
                    <li>Foley Cath care, JP-tube maintenance, Plasma and Blood Transfusion- 3 years</li>
                  </ul>
                </div>
                <div className="b4_sbm_right">
                  <h3>April 2017- February 2020 </h3>
                </div>
              </div>
              <div className="block4_sub_block_min">
                <div className="b4_sbm_left">
                  <h2>Long Beach Drug and Alcohol Treatment Agency</h2>
                  <h6>Registered Nurse </h6>
                  <ul>
                    <li>Reviewed medical histories, conducted physical screenings, and arranged primary care referrals for high-risk
                      population groups including but not limited to substance users/ abusers, correction populations, HIV+
                      patients, homeless and dual diagnosed individuals residing in Long beach, CA. </li>
                    <li>Met with clients regarding medical and psychosocial health standing and contacted primary care providers
                      regarding medical history, lab results, and prescriptions as well as TB screenings.</li>
                    <li>Monitored HIV+ patient caseloads and worked in collaboration with their substance abuse counselor for
                      healthy patient outcome. Supervised Med-Line and Med-Line workers ensuring all policies and procedures.</li>
                    <li>Facilitated HIV awareness and Medication/Disease Management classes for program participants. </li>
                  </ul>
                </div>
                <div className="b4_sbm_right">
                  <h3>October 2016 - April 2017 </h3>
                </div>
              </div> */}

              <div className="block4_sub_block_min">
                <h2>Awards</h2>
                <ul>
                  <li>Sigma Alpha Pi- National Honor and Leadership Society University of Wyoming </li>
                  <li>Provost of Academic Affairs Honor Roll 2012-2016 University of Wyoming</li>
                  <li>President’s Academic Affairs Honor Roll 2012-2016 Sheridan College</li>
                </ul>
              </div>
              <div className="block4_sub_block_min main_black">
                <h2>Licensing, and Certifications</h2>
                <ul>
                  <li><p> Registered Nursing  License CA </p> <div className="black_line1"></div><p>License #95110112</p> </li>
                  <li><p>Registered Nursing License OR</p> <div className="black_line2"></div><p>License #202100842</p> </li>
                  <li><p>Registered Nursing License WY</p> <div className="black_line3"></div><p>License # 38362</p></li>
                  <li><p>Basic Life Support for Healthcare Providers</p> <div className="black_line4"></div><p>Current</p></li>
                  <li><p>Advanced Cardiac Life Support</p> <div className="black_line5"></div><p>Current</p></li>
                  <li><p>NPI Furnishing and DEA licenses</p> <div className="black_line6"></div><p>Current</p></li>
                  <li><p>AANP FNP Board Certification</p> <div className="black_line7"></div><p>Current</p></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}

export default Aboutus;
