import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
// import './assets/angular-material-css/deeppurple-amber.css';
import './assets/angular-material-css/indigo-pink.css';
// import './assets/angular-material-css/pink-bluegrey.css';
// import './assets/angular-material-css/purple-green.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import store from './store';
import './index.css';

import { Cookies } from 'react-cookie';
import Aboutus from './Pages/Aboutus/Aboutus';
import Enfdtesting from './Pages/Enfdtesting/Enfdtesting';
import Healthriskanalysis from './Pages/Healthriskanalysis/Healthriskanalysis';
import MeetOurProviders from './Pages/Meet-our-Providers/MeetOurProviders';
import Blog from './Pages/Blog/Blog';
import Testimonials from './Pages/Testimonials/Testimonials';
import Contact from './Pages/Contact/Contact';
import SpinalDecompressionTherapy from './Pages/SpinalDecompressionTherapy/SpinalDecompressionTherapy';
import NutritionalCounSeling from './Pages/NutritionalCounSeling/NutritionalCounSeling';
import LaserTherapy from './Pages/LaserTherapy/LaserTherapy';
import ElectricCellTreatment from './Pages/ElectricCellTreatment/ElectricCellTreatment';
import PainManagement from './Pages/PainManagement/PainManagement';
import MedicationManagement from './Pages/MedicationManagement/MedicationManagement';
import { setLogedinUserInfo } from './Pages/Login/loginReducer';
import ImageGallery from './Pages/ImageGallery/ImageGallery';
import VideoGallery from './Pages/VideoGallery/VideoGallery';
import AdvancedBiologics from './Pages/AdvancedBiologics/AdvancedBiologics';
import TestimonialDetails from './Pages/Testimonials/TestimonialDetails';
import BlogDetails from './Pages/Blog/BlogDetails';
import ImageDetails from './Pages/ImageGallery/ImageDetails';


// import SmallFiberNeuropathy from './Pages/SmallFiberNeuropathy/SmallFiberNeuropathy';




const Layout = React.lazy(() => import('./Layout/Layout'));
const NoPage = React.lazy(() => import('./Pages/NoPage/NoPage'));
const BlogBackend = React.lazy(() => import('./Pages/BlogBackend/BlogBackend'));
const TeamManagementBackend = React.lazy(() => import('./Pages/TeamManagementBackend/TeamManagementBackend'));
const AddUsersBackend = React.lazy(() => import('./Pages/AddUsersBackend/AddUsersBackend'))
const AdminDashboardBackend = React.lazy(() => import('./Pages/AdminDashboardBackend/AdminDashboardBackend'));
const TestimonialBackend = React.lazy(() => import('./Pages/TestimonialBackend/TestimonialBackend'));
const VideoGalleryBackend = React.lazy(() => import('./Pages/GalleryBackend/VideoGalleryBackend/VideoGalleryBackend'));
const ImageGalleryBackend = React.lazy(() => import('./Pages/GalleryBackend/ImageGalleryBackend/ImageGalleryBackend'));
const LocationManagementBackend = React.lazy(() => import('./Pages/LocationManagementBackend/LocationManagementBackend'));
const AddLocationBackend = React.lazy(() => import('./Pages/AddLocationBackend/AddLocationBackend'));
const SocialMediaAdvoBackend = React.lazy(() => import('./Pages/SocialMediaAdvoBackend/SocialMediaAdvoBackend'));
const BannerBackend = React.lazy(() => import('./Pages/BannerManagement/BannerBackend/BannerBackend'));

const MyAccount = React.lazy(() => import('./Pages/MyAccount/MyAccount'));
const ForgotPassword = React.lazy(() => import('./Pages/MyAccount/ForgotPassword'));
const ForgotPasswordPage = React.lazy(() => import('./Pages/MyAccount/ForgotPasswordPage'));
const PostManagement = React.lazy(() => import('./Pages/PostManagement/PostManagementBackend'));
const TagManagement = React.lazy(() => import('./Pages/TagManagement/TagManagementBackend'));
const SocialAdvocate = React.lazy(() => import('./Pages/SocialAdvocate/SocialAdvocate'));


// =============================FRONTEND PAGES Lazy Loading=======================================================

const Home = React.lazy(() => import('./Pages/Home/Home'));

const Pharmacogenetic = React.lazy(() => import('./Pages/Pharmacogenetic/Pharmacogenetic'));
const PeripheralArteryDisease = React.lazy(() => import('./Pages/PeripheralArteryDisease/PeripheralArteryDisease'));
const SmallFiberNeuropathy = React.lazy(() => import('./Pages/SmallFiberNeuropathy/SmallFiberNeuropathy'));
const Neuropathy = React.lazy(() => import('./Pages/Neuropathy/Neuropathy'));
const MedicalFoods = React.lazy(() => import('./Pages/MedicalFoods/MedicalFoods'));
const Login = React.lazy(() => import('./Pages/Login/Login'));





////////////////////////////////////////// Get Cookies from browser and set into Store ////////////////////////////////////
const getCookiesSetStore = () => {
  const cookies = new Cookies();
  const getcookies = cookies.getAll();
  console.log("getcookies from index==>", getcookies);
  if (getcookies !== null && getcookies !== undefined && Object.keys(getcookies).length > 0) {
    console.log('getcookies==>', getcookies)
    store.dispatch(setLogedinUserInfo(getcookies));
  }
}
////////////////////////////////// Call the getCookiesSetStore function /////////////////////////////////////
getCookiesSetStore();

const useAuth = () => {
  console.log("AUTHED++++++++++", store.getState().loginSlice.isLoggedIn)
  return store.getState().loginSlice.isLoggedIn;
}
const LoginRoute = ({ children, redirectPath }) => {
  console.log("index---------children", children, "index---------redirectPath", redirectPath);
  const auth = useAuth();
  return (auth !== true) ? children : <Navigate to={redirectPath} replace={true} />
}
const PrivateRoute = ({ children }) => {
  return children
  // const auth = useAuth();
  // return (
  //   auth === true ? children : <Navigate to="/login" replace={true} />
  // )
}
const PasswordRoute = ({ children }) => {
  const auth = useAuth();
  return (auth !== true) ? children : <Navigate to="/login" replace={true} />
}





function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          {/* ------------------------------- Login Route ---------------------------------- */}

          <Route path='/' element={<Suspense fallback={<></>}><Layout /></Suspense>}>
            {/* ------------------------------------ Frontend Pages ------------------------------ */}

            {/* ++++++++++++++ Home +++++++++++++++++ */}
            <Route index element={<Suspense fallback={<></>}><Home /></Suspense>} />
            <Route path='/pharmacogenetic' element={<Suspense fallback={<></>}><Pharmacogenetic /></Suspense>} />
            <Route path='/peripheral-artery-disease' element={<Suspense fallback={<></>}><PeripheralArteryDisease /></Suspense>} />
            <Route path='/meet-our-provider' element={<Suspense fallback={<></>}><Aboutus /></Suspense>} />
            <Route path='/small-fiber-neuropathy' element={<Suspense fallback={<></>}><SmallFiberNeuropathy /></Suspense>} />
            <Route path='/enfd-testing' element={<Suspense fallback={<></>}><Enfdtesting /></Suspense>} />
            <Route path='/health-risk-analysis' element={<Suspense fallback={<></>}><Healthriskanalysis /></Suspense>} />
            <Route path='/neuropathy' element={<Suspense fallback={<></>}><Neuropathy /></Suspense>} />
            <Route path='/contact' element={<Suspense fallback={<></>}><Contact /></Suspense>} />
            <Route path='/medical-foods' element={<Suspense fallback={<></>}><MedicalFoods /></Suspense>} />
            <Route path='/meet-our-providers' element={<Suspense fallback={<></>}><MeetOurProviders /></Suspense>} />
            <Route path='/blog' element={<Suspense fallback={<></>}><Blog /></Suspense>} />
            <Route path='blog-detail/:_id' element={<Suspense fallback={<></>}><BlogDetails /></Suspense>} />
            <Route path='/testimonials' element={<Suspense fallback={<></>}><Testimonials /></Suspense>} />
            <Route path='frontend/testimonial-details/:_id' element={<Suspense fallback={<></>}><TestimonialDetails /></Suspense>} />
            <Route path='frontend/testimonials/add/:param' element={<Suspense fallback={<></>}><TestimonialBackend type='frontend-add' /></Suspense>} />
            <Route path='/spinal-decompression-therapy' element={<Suspense fallback={<></>}><SpinalDecompressionTherapy /></Suspense>} />
            <Route path='/nutritional-counseling' element={<Suspense fallback={<></>}><NutritionalCounSeling /></Suspense>} />
            <Route path='/laser-therapy' element={<Suspense fallback={<></>}><LaserTherapy /></Suspense>} />
            <Route path='/nutritional-counseling' element={<Suspense fallback={<></>}><NutritionalCounSeling /></Suspense>} />
            <Route path='/electric-cell-treatment' element={<Suspense fallback={<></>}><ElectricCellTreatment /></Suspense>} />
            <Route path='/pain-management' element={<Suspense fallback={<></>}><PainManagement /></Suspense>} />
            <Route path='/medication-management' element={<Suspense fallback={<></>}><MedicationManagement /></Suspense>} />
            <Route path='/advanced-biologics' element={<Suspense fallback={<></>}><AdvancedBiologics /></Suspense>} />
            <Route path='/image-gallery' element={<Suspense fallback={<></>}><ImageGallery /></Suspense>} />
            <Route path='img-details/:_id' element={<Suspense fallback={<></>}><ImageDetails type='imgDetails' /></Suspense>} />
            <Route path='/video-gallery' element={<Suspense fallback={<></>}><VideoGallery /></Suspense>} />
            <Route path='/videogallery/:_id' element={<Suspense fallback={<></>}><VideoGallery /></Suspense>} />





            {/* ------------------------------------ Backend Pages ------------------------------ */}

            <Route path="login" element={<LoginRoute redirectPath={`/${(store?.getState()?.loginSlice?.userType)}/dashboard`}><Suspense fallback={<></>}><Login /></Suspense></LoginRoute>} />


            {/* ++++++++++++++ Dashboard +++++++++++++++++ */}
            <Route path='admin/dashboard' element={<PrivateRoute><Suspense fallback={<></>}><AdminDashboardBackend /></Suspense></PrivateRoute>} />

            {/* -------------------------------Team Management-------------------------------- */}
            <Route path='team/list' element={<Suspense fallback={<></>}><TeamManagementBackend type='list' /></Suspense>} />

            <Route path='admin/edit-admin' element={<PrivateRoute><Suspense fallback={<></>}><TeamManagementBackend type='editAdmin' /></Suspense></PrivateRoute>} />
            <Route path='admin/edit-patient' element={<PrivateRoute><Suspense fallback={<></>}><TeamManagementBackend type='editPatient' /></Suspense></PrivateRoute>} />
            <Route path='admin/edit-doctor' element={<PrivateRoute><Suspense fallback={<></>}><TeamManagementBackend type='editDoctor' /></Suspense></PrivateRoute>} />
            <Route path='admin/edit-doctor-assistant' element={<PrivateRoute><Suspense fallback={<></>}><TeamManagementBackend type='editDoctorAssistant' /></Suspense></PrivateRoute>} />
            <Route path='admin/edit-social-advocate' element={<PrivateRoute><Suspense fallback={<></>}><TeamManagementBackend type='editSocialAdvocate' /></Suspense></PrivateRoute>} />

            <Route path='admin/add-admin' element={<PrivateRoute><Suspense fallback={<></>}><AddUsersBackend type='admin' /></Suspense></PrivateRoute>} />
            <Route path='admin/add-doctor' element={<PrivateRoute><Suspense fallback={<></>}><AddUsersBackend type='doctor' /></Suspense></PrivateRoute>} />
            <Route path='admin/add-doctor-assistant' element={<PrivateRoute><Suspense fallback={<></>}><AddUsersBackend type='doctor-assistant' /></Suspense></PrivateRoute>} />
            <Route path='admin/add-patient' element={<PrivateRoute><Suspense fallback={<></>}><AddUsersBackend type='patient' /></Suspense></PrivateRoute>} />
            <Route path='admin/add-social-advocate' element={<PrivateRoute><Suspense fallback={<></>}><AddUsersBackend type='social-advocate' /></Suspense></PrivateRoute>} />


            <Route path='social-advocate/dashboard' element={<PrivateRoute><Suspense fallback={<></>}><SocialAdvocate type='social-advocate' /></Suspense></PrivateRoute>} />

            {/* ++++++++++++++ Testimonial +++++++++++++++++ */}
            <Route path='testimonial/list' element={<PrivateRoute><Suspense fallback={<></>}><TestimonialBackend type='list' /></Suspense></PrivateRoute>} />
            <Route path='testimonial/add' element={<PrivateRoute><Suspense fallback={<></>}><TestimonialBackend type='add' /></Suspense></PrivateRoute>} />
            <Route path='testimonial/edit' element={<PrivateRoute><Suspense fallback={<></>}><TestimonialBackend type='edit' /></Suspense></PrivateRoute>} />

            {/* -----------------------blog--------------------------- */}
            <Route path='blog/list' element={<Suspense fallback={<></>}><BlogBackend type='list' /></Suspense>} />
            <Route path='blog/add' element={<Suspense fallback={<></>}><BlogBackend type='add' /></Suspense>} />
            <Route path='blog/edit/:blog_id' element={<PrivateRoute><Suspense fallback={<></>}><BlogBackend type='edit' /></Suspense></PrivateRoute>} />

            {/*****************************Post Mangement****************** */}
            <Route path='post/list' element={<PrivateRoute><Suspense fallback={<></>}><PostManagement type='list' /></Suspense></PrivateRoute>} />
            <Route path='post/add' element={<PrivateRoute><Suspense fallback={<></>}><PostManagement type='add' /></Suspense></PrivateRoute>} />
            <Route path="post/edit/:id" element={<PrivateRoute><Suspense fallback={<></>}><PostManagement type='edit' /></Suspense></PrivateRoute>} />

            {/*****************************Tag Mangement****************** */}
            <Route path='tag/list' element={<PrivateRoute><Suspense fallback={<></>}><TagManagement type='list' /></Suspense></PrivateRoute>} />
            <Route path='tag/add' element={<PrivateRoute><Suspense fallback={<></>}><TagManagement type='add' /></Suspense></PrivateRoute>} />
            <Route path="tag/edit/:id" element={<PrivateRoute><Suspense fallback={<></>}><TagManagement type='edit' /></Suspense></PrivateRoute>} />

            {/*++++++++++++++++++++++++++++++++++Backend Gallery++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/}
            <Route path='gallery/add/video' element={<PrivateRoute><Suspense fallback={<></>}><VideoGalleryBackend type='add' /></Suspense></PrivateRoute>} />
            <Route path='gallery/edit/video' element={<PrivateRoute><Suspense fallback={<></>}><VideoGalleryBackend type='edit' /></Suspense></PrivateRoute>} />

            <Route path='gallery/video/list' element={<PrivateRoute><Suspense fallback={<></>}><VideoGalleryBackend type='list' /></Suspense></PrivateRoute>} />
            <Route path='gallery/add/image' element={<PrivateRoute><Suspense fallback={<></>}><ImageGalleryBackend type='add' /></Suspense></PrivateRoute>} />
            <Route path='gallery/edit/image' element={<PrivateRoute><Suspense fallback={<></>}><ImageGalleryBackend type='edit' /></Suspense></PrivateRoute>} />

            <Route path='gallery/image/list' element={<PrivateRoute><Suspense fallback={<></>}><ImageGalleryBackend type='list' /></Suspense></PrivateRoute>} />

            {/* ++++++++++++++ Location Management +++++++++++++++++ */}
            <Route path='location/list' element={<PrivateRoute><Suspense fallback={<></>}><LocationManagementBackend type='list' /></Suspense></PrivateRoute>} />
            <Route path='/location/add-location' element={<PrivateRoute><Suspense fallback={<></>}><AddLocationBackend type='add' /></Suspense></PrivateRoute>} />
            <Route path='/location/edit-location' element={<PrivateRoute><Suspense fallback={<></>}><AddLocationBackend type='edit' /></Suspense></PrivateRoute>} />


            {/* ++++++++++++++ Social Media Advo +++++++++++++++++ */}
            <Route path='social-media-advo/list' element={<PrivateRoute><Suspense fallback={<></>}><SocialMediaAdvoBackend type='list' /></Suspense></PrivateRoute>} />
            <Route path='social-media-advo/add' element={<PrivateRoute><Suspense fallback={<></>}><SocialMediaAdvoBackend type='add' /></Suspense></PrivateRoute>} />
            <Route path='social-media-advo/edit' element={<PrivateRoute><Suspense fallback={<></>}><SocialMediaAdvoBackend type='edit' /></Suspense></PrivateRoute>} />



            {/*++++++++++++++++++++++++++++++++++Backend Banner++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/}

            <Route path='banner/list' element={<PrivateRoute><Suspense fallback={<></>}><BannerBackend type='list' /></Suspense></PrivateRoute>} />
            <Route path='banner/add' element={<PrivateRoute><Suspense fallback={<></>}><BannerBackend type='add' /></Suspense></PrivateRoute>} />
            <Route path='banner/edit' element={<PrivateRoute><Suspense fallback={<></>}><BannerBackend type='edit' /></Suspense></PrivateRoute>} />

            {/*----------------------------- my account -------------------------------------*/}
            <Route path='myaccount' element={<PrivateRoute><Suspense fallback={<></>}><MyAccount type='profile' /></Suspense></PrivateRoute>} />
            <Route path='change-password' element={<PrivateRoute><Suspense fallback={<></>}><MyAccount type="password" /></Suspense></PrivateRoute>} />
            <Route path="forgot-password" element={<PasswordRoute><Suspense fallback={<></>}><ForgotPassword /></Suspense></PasswordRoute>} />
            <Route path="forgot-password/:_id/:token" element={<PasswordRoute><Suspense fallback={<></>}><ForgotPasswordPage /></Suspense></PasswordRoute>} />




          </Route>
          {/* ----------------------------------------- No Page --------------------------------- */}
          <Route path='*' element={<Suspense Suspense fallback={<></>}> <NoPage /></Suspense>} />
        </Routes>
      </BrowserRouter>
    </Provider>
  )
}

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
