import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import store from "../../store";

export const getLocationSearchList = createAsyncThunk("location/search/list", async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const responseData = await fetch(process.env.REACT_APP_API_URL + "api1/listinglocations", requestOptions);
    const responseCount = await fetch(process.env.REACT_APP_API_URL + "api1/listinglocationcount", requestOptions);
    const respData = await responseData.json();
    const respCount = await responseCount.json();
    return { respData, respCount };
});


export const getLocationList = createAsyncThunk("location/list", async (reqbody) => {
    const accessToken = (store.getState().loginSlice?.token); /// access token
    const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token

    // console.log("reqbody==>", reqbody);
    if (reqbody == null && reqbody == undefined) {
        reqbody = {
            "source": "location_views",
            "condition": {
                "limit": 5,
                "skip": 0
            }, 
            "sort": {
                "type": "desc",
                "field": "_id"
            },
            'token': accessToken,
            'email': userMail,
            "searchcondition": {},
            "relation":"Backend"
        }
    }

    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/listinglocations", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});

  


  /****************Change Status Location Management******************** */


  export const updateStatuslocation = createAsyncThunk("LocationManagement/statuschange", async (reqbody) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/changestatuslocation", requestOptions);
    const respdata = await response.json();
    return respdata;

})


/**********Multiple Edit User- Only Change Status*************************** */
export const locationStatusUpdateMultiple = createAsyncThunk("LocationManagement/multiedit", async (reqbody) => {



    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/multipleeditlocationstatus", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
});


  /***************************Multiple Delete- Users***************************** */

export const locationMultiDelete = createAsyncThunk("LocationManagement/multidelete", async (reqbody) => {
    const accessToken = (store.getState().loginSlice?.token); /// access token
    const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token
    let req_body = {};
    req_body = { _ids:reqbody ,source: "locations", email: userMail, token: accessToken };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/multideletelocation", requestOptions);
    const respdata = await response.json();
    return respdata;
});


const locationManagement = createSlice({
    name: 'locationManagement',
    initialState: {
        message: null,
        loading: false,
        success: false,
        submitted: false,
        submitting: false,
        successmsg: "",
        locationList: [],
        locationCount: null,
        preview: null,
        editDataforlocation: {},
        statusChangeFlag : null,
        multiDeleteFlage:null,
        maltiDeleteFlage: null,
        multiStatusFlage:null,
        statusUpdateFlage: null,

       

    },
    reducers: {
        setSuccessAfterSubmit(state, action) {
            state.success = false;
        },
        resetManageaccountflag(state, action) {
            state.manageAccountflag = {}
        },
        setEditDataLocation(state, action) {
            console.log("state from seteditdata==>", state, action);
            if (action.payload !== undefined && action.payload != null) {
                state.editDataforlocation = action.payload;
            }
        },
        clearEditData(state, action) {
            console.log("state from seteditdata==>", state, action);
            state.editDataforlocation = {};
        },
        clearUserListing(state, action){
            state.locationData = [];
          },
          clearmaltiDeleteFlage(state, action) {
            state.maltiDeleteFlage = null;
        },
        clearmultiStatusFlage(state, action) {
            state.multiStatusFlage = null;
        },

        clearstatusUpdateFlage(state, action) {
            state.statusUpdateFlage = null;
        },
    },
    extraReducers: {
    // ---------------------locatin search--------------------------//

        [getLocationSearchList.pending]: (state, action) => {
            state.loading = true;
            state.locationData = null;
            state.message = null;
        },
        [getLocationSearchList.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.respData.status === 'success' && action.payload.respCount.status === 'success') {
                state.locationList = action.payload.respData.results.res;
                state.locationCount = action.payload.respCount.count;
                state.message = action.payload.respData.status;
            }
        },
        [getLocationSearchList.rejected]: (state, action) => {
            state.loading = false;
        },
// -------------------------------location list-----------------------------//
        [getLocationList.pending]: (state, action) => {
            state.loading = true;
            state.locationList = null;
        },
        [getLocationList.fulfilled]: (state, action) => {

            if (action.payload.status === "success") {

                state.locationList = action.payload.results.res;
          
                state.loading = false;

              

               
            }
        },
        [getLocationList.rejected]: (state, action) => {
            state.loading = false;
        },

        //******************Update Status**************************** */
     
        [updateStatuslocation.pending]: (state, action) => {
          state.loading = true;
          state.success = false;
        //   state.statusChangeFlag = false
          state.statusUpdateFlage = true;
       },
        [updateStatuslocation.fulfilled]: (state, action) => {
          
          if (action.payload.status == 'success') {
              state.loading = false;
            //   state.success = true;
              state.submitting = false;
            //   state.statusChangeFlag= false;
              state.statusUpdateFlage = false;
          }
      },
      [updateStatuslocation.rejected]: (state, action) => {
        state.loading = false;
        // state.statusChangeFlag = false;
        state.statusUpdateFlage = false;
       },

       

      /*******************Multiple Edit Users - Only status change********** */
      [locationStatusUpdateMultiple.pending]: (state, action) => {
        state.loading = true;
        state.statusUpdateFlage = true;
      },
      [locationStatusUpdateMultiple.fulfilled]: (state, action) => {
        if (action.payload.status === "success") {
          state.loading = false;
          state.successmsg = 'Updated Successfully'
          state.success = true;
          state.statusUpdateFlage = false;
        }
      },
      [locationStatusUpdateMultiple.rejected]: (state, action) => {
        state.loading = false;
        state.statusUpdateFlage = false;
        state.successmsg = 'Something went wrong';
      },

      /*-------------------------- Delete Multiple Location--------------------------*/
        

      [locationMultiDelete.pending]: (state, action) => {
        state.loading = true;
        state.maltiDeleteFlage = true;
     
         },
      [locationMultiDelete.fulfilled]: (state, action) => {
        if (action.payload.status === "success") {
            state.loading = false;
            state.maltiDeleteFlage = false;
         
            state.successmsg = 'Deleted Successfully';
            
        }

    },
    [locationMultiDelete.rejected]: (state, action) => {
        state.loading = false;
        state.successmsg = 'Something went wrong';
        state.maltiDeleteFlage = false;


    },

        

  
    }
})


export default locationManagement.reducer;
export const { setSuccessAfterSubmit, resetManageaccountflag,setEditDataLocation, clearEditData,clearUserListing,clearmaltiDeleteFlage,clearmultiStatusFlage,clearstatusUpdateFlage } = locationManagement.actions;