import React, { useEffect, useRef, useState } from "react";
import "../ImageGallery/ImageGallery.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "../../index2.css";
import "./ImageGallery.css";
import { Grid, LinearProgress } from "@mui/material";
import { getEventData, randomId } from "../../helper/helperFunctions";
import { clearImageGalList, getdataforImagelist } from "../GalleryBackend/ImageGalleryBackend/ImageGalleryReducer";



const reqBody = {
    condition: {
        limit: 5,
        skip: 0
    },
    searchcondition: {
        status: 1
    },
    source: "image_views",
    sort: { type: "asc", field: "priority" },
    projection: {
        image: 1,
        title: 1,
        description: 1,
    },
    relation: 'Frontend'
}
export default function ImageGallery() {

    // ---------------------------------------------Invoking Hooks--------------------------------------------- //
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const scrollToBlog = useRef();

    // ----------------------------------------Global State from Redux---------------------------------------- //
    const resolved = useSelector(state => state.layoutSlice.resolved['/img-gallery'] ? state.layoutSlice.resolved['/img-gallery'] : false);//for resolve
    const imgArr = useSelector((state) => state.imagegallerySlice?.imgArr ? state.imagegallerySlice.imgArr : "");
    const loading = useSelector(state => state.imagegallerySlice?.loading);

    // -------------------------------------------useEffect Section------------------------------------------- //
    useEffect(() => {
        // console.log('Before-After-Pictures ---> useEffect 1');
        if (!resolved && imgArr.length === 0) dispatch(getdataforImagelist(reqBody));

        return () => { dispatch(clearImageGalList()) }
    }, []);

    // For auto scroll to blog in the blog page
    useEffect(() => scrollToBlog.current.scrollIntoView({ behavior: "smooth" }), [scrollToBlog]);

    // For perform action on element event
    useEffect(() => {

        let resolveData = {
            path: "/img-details",
            resolveFunc: [getdataforImagelist],
            body: { ...reqBody, relation: 'Frontend Details' },
        };

        getEventData(imgArr, navigate, 'img-gallery', 'img-gallery-event', 'elem-event-data', '/img-details/', reqBody, 'img-details',resolveData, dispatch)
    }

        , [imgArr]);

    // <---------------------------------------Functions for return jsx---------------------------------------> //
    const getImgGall = () => {
        let newArrayOfObj = imgArr.map(({
            image: images,
            description: desc,
            createdon_datetime: creationTime,
            ...rest
        }) => ({
            images,
            loaderPath: "https://all-frontend-assets.s3.amazonaws.com/inland-empire-integrated-medicine-images/imgpsh_fullsize_anim-min.jpeg",
            desc,
            creationTime,
            shareLink: ["Facebook", "Twitter", "Linkedin"],
            ...rest
        }));

        let imgList = {
            loaderPath: "https://all-frontend-assets.s3.amazonaws.com/inland-empire-integrated-medicine-images/imgpsh_fullsize_anim-min.jpeg",
            shareLinks: ["Facebook", "Twitter", "Linkedin"],
            initialArr: newArrayOfObj,
            apiCall: {
                apiUrl: process.env.REACT_APP_API_URL + "api1/imagelisting",
                body: reqBody
            },
        }
        return (<img-gallery key={randomId} data={JSON.stringify(imgList)} />)
    }




    return (
        <>
            <div className="imagegallery_banner common_banner_sec">
        <div className="banner_body">
          <div className="banner_wrapper">
            <div className="banner_wrpr_bg">
              <div className="service_banner_con">
                <div className="common_width">
                  <div className="banner_con_text">
                    <div className="banner_con_text1 font55">
                      <h1>Image Gallery </h1>
                      <h5>
                      Call us <a className="banner_link" href="tel:(951) 808-5221">(951) 808-5221</a>

                        <span>
                          Visit us at 802 Magnolia Ave Ste 103 Corona, CA 92879
                        </span>
                      </h5>
                      <a
                        href="https://qualitypracticeweb.com/inlandlight" target={"_blank"}
                        className="green_button"
                      >
                        <h6>Request an appointment here</h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




            <div className="officetour_section">
                <div className="tour_photos_block1 block-padding">
                    <div className="AFTR_B4_block2">
                        <div className="common_width">
                            <div className="aftr_b4_block2_innr">
                                <div className="aftr_b4_wrpr common_block">
                                    <h2 ref={scrollToBlog}>Check out our latest uploaded image of Inland Empire Integrated Medicine</h2>
                                </div>
                                <div>{loading ? <LinearProgress className='' /> : null}</div>

                                <div className="chrctr_aftr_b4_wrpr">
                                    {/* <------------------------------Img Card List From angular element------------------------------> */}
                                    {imgArr && imgArr.length > 0 ?
                                        getImgGall() : <div className='noDataAvailable' ><img src="https://all-frontend-assets.s3.amazonaws.com/Galveston/assets/images/no_result_image.png" /></div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </div>









        </>
    )
}
