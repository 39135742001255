import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

//////////////////////////////////for my account//////////////////////////////////////////
export const getUserData = createAsyncThunk("myaccount", async (reqbody) => {
  console.log("getuserdetailshitttttttttttttt", reqbody);
  // const requestOptions = {
  //   method: "POST",
  //   headers: { "Content-Type": "application/json" },
  //   body: JSON.stringify(reqbody),
  // };
  const response = await fetch(
    `${process.env.REACT_APP_API_URL_NODE}api2/userdetails?_id=${reqbody.searchcondition._id}`,
    // requestOptions
  );
  console.log("getUserData==>", response);

  const resData = await response.json();
  console.log("getUserData==>", resData);

  return resData;
});

//////////////////////////// For forgot password ////////////////////////
export const forgotPassword = createAsyncThunk(
  "forgotpassword",
  async (reqBody) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reqBody),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}api2/forgotpassword`,
      requestOptions
    );
    console.log("Next 222 step", response)
    const res = await response.json();

    return res;
  }
);

const accountSlice = createSlice({
  name: "accountSlice",
  initialState: {
    submitting: false,
    profileInfo: [],
    loading: false,
  },
  reducers: {},
  extraReducers: {
    //////////////////////////////////// Fetching User  ///////////////////////////////\
    [getUserData.pending]: (state, action) => {
      state.submitting = true;
      state.loading = true;
    },
    [getUserData.fulfilled]: (state, action) => {
      if (action.payload.status === "success") {
        state.profileInfo = [action.payload.results.res[0]];
        console.log("first", action.payload.results.res[0]);
        state.submitting = false;
        state.loading = false;
      }
    },
    [getUserData.rejected]: (state, action) => {
      state.submitting = false;
      state.loading = false;
    },
    //////////////////////////////////// forgotpassword  ///////////////////////////////\
    [forgotPassword.pending]: (state, action) => {
      state.submitting = true;
      state.loading = true;
    },
    [forgotPassword.fulfilled]: (state, action) => {
      if (action.payload.status === "success") {
        state.submitting = false;
        state.loading = false;
      }
    },
    [forgotPassword.rejected]: (state, action) => {
      state.submitting = false;
      state.loading = false;
    },
  },
});
export const {} = accountSlice.actions;
export default accountSlice.reducer;
