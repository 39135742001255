import React from "react";
import "../../index.css";
import "../NutritionalCounSeling/NutritionalCounSeling.css";

function NutritionalCounSeling() {
  return (
     <>
      
   
      <div className="counseling_banner common_banner_sec">
        <div className="banner_body">
          <div className="banner_wrapper">
            <div className="banner_wrpr_bg">
              <div className="service_banner_con">
                <div className="common_width">
                  <div className="banner_con_text">
                    <div className="banner_con_text1 font55">
                      <h1>Nutritional Counseling</h1>
                      <h5>
                        Call us <a className="banner_link" href="tel:(951) 808-5221">(951) 808-5221</a>{" "}
                        <span>
                          Visit us at 802 Magnolia Ave Ste 103 Corona, CA 92879
                        </span>
                      </h5>
                      <a href ="https://qualitypracticeweb.com/inlandlight"  target={"_blank"} className="green_button">
                        <h6>Request an appointment here</h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="counseling_block1">
        <div className="left_image_and_text">
          <div className="blue_text_background block-padding">
            <div className="common_width">
              <div className="text_position">
                <div className="textwrapper white-title-white-description">
                  <h2>
                    Nutritional Counseling in Corona, CA with <br /> Robert S. Krage D.C.
                  </h2>
                  <p>
                    As doctors of chiropractic we don’t just deal with the
                    spine, our profession covers the physical health of the body
                    meaning that we offer nutritional counseling and customized
                    programs to those patients that want to gain a better
                    understanding of their health.
                  </p>
                  <p>
                    Inland Empire Integrated Medicine centers their practice
                    around comprehensive, non-invasive, non-pharmaceutical
                    treatments and lifestyle changes that bring continuous
                    results. If you are looking to make lifelong changes to your
                    life and health, this practice may be the best option. One
                    of the most important aspects of being healthy is having a
                    healthy and balanced diet. In today’s world, there are
                    numerous companies that are offering fast weight loss plans
                    and poor nutritional advice that are leaving people lacking
                    in their vital nutrients and vitamins. But the physicians
                    here are trained in nutrition and educating their patients
                    on how to balance weight loss and a healthy diet, so they
                    are keeping their patients in the healthiest state possible.
                  </p>
                  <p>
                    We offer food programs where a patient may visit and voice
                    their hopes and concerns for their health, and our medical
                    professionals give their professional advice, and customize
                    a plan to help patients receive the necessary nutrients the
                    body needs to stay healthy.{" "}
                  </p>
                  <p>
                    The programs that we offer have a wide variety of focuses
                    including decreasing inflammation, improving blood sugar
                    regulation, detoxification, and maintaining a healthy diet.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="counseling_block2 block-padding">
        <div className="common_width">
          <div className="flex_wrapper">
            <div className="textwrapper blue-title-black-description">
              <h2>What Nutritional Counseling Helps With:</h2>
              <div className="block_listwrapper colun_count2">
                <p>Boosting Metabolism </p>
                <p>Detoxifying the Body</p>
                <p>Helping Increase Energy </p>
                <p>Eliminates Unhealthy Cravings</p>
                <p>Increases Nerve Function for Diet Control</p>
                <p>Risk Free Weight Loss</p>
              </div>
            </div>

            <div className="image_wrapper image_wrapper_padding">
              <div className="image_inner_wrapper">
                <img src={`${process.env.REACT_APP_IMG_URL}counseling_block2_fruit.webp`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NutritionalCounSeling;
