import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, LinearProgress, Tooltip } from "@mui/material";
import "../../../src/index2.css";
import "./ImageGallery.css";
import { clearImgGalDetail,clearImageGalList,getdataforImagelist } from '../GalleryBackend/ImageGalleryBackend/ImageGalleryReducer';
import DescriptionModification from '../Blog/DescriptionModification';
import HelpIcon from '@mui/icons-material/Help'
import AddIcon from '@mui/icons-material/Add';

function ImageDetails() {
  // ---------------------------------------------Invoking Hooks--------------------------------------------- //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathParam = useParams();
  const { pathname, state } = useLocation();
  const scrollToTestimonial = useRef();
  const { _id } = useParams();
  console.log("_id======>",_id);

  const [openDescriptionModificationModal,setDescriptionModificationModal] =useState(false); //for Description Modification for Meta

  const formSubmissionState = useSelector(state => state.formSlice.formSubmissionState.descriptionModification);

  const userType = useSelector((state)=>state.loginSlice.userInfo.type === "admin")



  // ----------------------------------------Global State from Redux---------------------------------------- //
  const { imgArr, singleImgCard } = useSelector((state) => state.imagegallerySlice?.imgGalDetail);
  const loading = useSelector((state) => state.imagegallerySlice?.loading);
  const resolved = useSelector((state) => state.layoutSlice.resolved["/img-details"] ? state.layoutSlice.resolved["/img-details"] : false); //for resolve

  // ----------------------------------------Components Private State---------------------------------------- //
  const [imgDetailData, setImgDetailData] = useState(null)

  // --------------------------------------------Other Veriables-------------------------------------------- //
  const pathWithoutParam = pathname.slice(1, pathname.lastIndexOf('/'));
  console.log("pathWithoutParam",pathWithoutParam,pathname);

  // --------------------------------------------useEffect Section-------------------------------------------- //
  useEffect(() => {
    if (!resolved && imgArr.length === 0) {
    dispatch(clearImgGalDetail("Frontend Details"));
    dispatch(getdataforImagelist({ ...state, searchcondition: { ...state.searchcondition, _id: { $ne: pathParam._id } }, relation: 'Frontend Details' }));
    }
    return () => dispatch(clearImgGalDetail("Frontend Details"));
  }, [])

  // For auto scroll to blog in the blog page
  useEffect(() => scrollToTestimonial.current.scrollIntoView({ behavior: "smooth" }), [scrollToTestimonial]);

  // For setting 'img-detail' element data
  useEffect(() => {
    // console.log('image-details ---> useEffect 2', imgArr);
    if (imgArr && imgArr.length > 0 && singleImgCard) {

      const newArrayOfObj = imgArr.map(({
        image: images,
        description: desc,
        createdon_datetime: creationTime,
        ...rest
      }) => ({
        images,
        loaderPath: "https://all-frontend-assets.s3.amazonaws.com/inland-empire-integrated-medicine-images/imgpsh_fullsize_anim-min.jpeg",
        desc,
        creationTime,
        shareLink: ["Facebook", "Twitter", "Linkedin"],
        ...rest
      }));

      setImgDetailData({
        apiCall: {
          apiUrl: process.env.REACT_APP_API_URL + "api1/imagelisting",
          body: { ...state, searchcondition: { ...state.searchcondition, _id: { $ne: singleImgCard._id } }, relation: 'Frontend Details' }
        },
        imgList: newArrayOfObj,
        _id: pathParam._id,
        title: singleImgCard?.title,
        images: singleImgCard?.image,
        loaderPath: "https://all-frontend-assets.s3.amazonaws.com/inland-empire-integrated-medicine-images/imgpsh_fullsize_anim-min.jpeg",
        desc: singleImgCard?.description,
        shareLink: ["Facebook", "Twitter", "Linkedin"],
        // imgGalMainWrap: "cardWrapper",
        // imgWrap: "wrapImg",
        // imgGalContWrap: "wrapCont",
      });
    }
  }, [imgArr, singleImgCard]);

  useEffect(() => {
    // console.log("singleImgCard ------------>", singleImgCard);
    let domNodeArr = null;
    setTimeout(() => {
      domNodeArr = document.querySelectorAll('img-detail');
      if (domNodeArr && domNodeArr.length > 0
        // && singleImgCard && Object.keys(singleImgCard).length > 0
      ) {
        for (let i = 0; i < domNodeArr.length; i++) {
          if (domNodeArr[i]) domNodeArr[i].addEventListener('img-detail-event', (e) => {
            let eventData = JSON.parse(e.target.getAttribute('elem-event-data'));
            // console.log("img-detail elem event Data --------->", eventData);
            switch (eventData.type) {
              case "read_more": {
                navigate(`/img-details/${eventData._id}`, { state: state ? state : null });
                window.location.reload();
              }
                break;
              case "shareInFacebook": window.open(`https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_API_EXTERNAL_URL}${pathWithoutParam}/${eventData._id}`, "", "width=500,height=300")
                break;
              case "shareInTwitter": window.open(`https://twitter.com/intent/tweet?url=${process.env.REACT_APP_API_EXTERNAL_URL}${pathWithoutParam}/${eventData._id}`, "", "width=500,height=300");
                break;
              case "shareInLinkedin": window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${process.env.REACT_APP_API_EXTERNAL_URL}${pathWithoutParam}/${eventData._id}`, "", "width=500,height=300");
                break;
              default: console.log("Inside Default Case of switch-case");
                break;
            }
          });

        }
      }
    }, 1000);

  }, [imgArr]);



  const handleOpenDescriptionModification = () => {
    setDescriptionModificationModal(true);
  };
  const handleCloseDescriptionModification = () => {
    setDescriptionModificationModal(false);
  };



  useEffect(() => {
   
    if (formSubmissionState && formSubmissionState == 2) {
      setDescriptionModificationModal(false);
    
      
    }
  }, [formSubmissionState]);



  //----------------------------------------------return-----------------------------------------------//
  return (
    <>

      <div className='demo_wrapper'>
        <img src='https://all-frontend-assets.s3.amazonaws.com/IEIM/assets/demo_image_gallery_banner.jpg' />

      </div>

      <div className="ImageDetails_section">

        <div className="tour_photos_block1 block-padding">
          <div className="common_width">
            <div className="aftr_b4_block2_innr">
              <div className="aftr_b4_wrpr common_block">
                <h2 ref={scrollToTestimonial}>Check out our latest uploaded image of Inland Empire Integrated Medicine</h2>


             {userType && <div className="add_testimonial_btnwrapper">
              <Button className="image_details"    onClick={() => handleOpenDescriptionModification()}>Coustom Meta</Button>
          </div>}



              </div>
              <div>{loading ? <LinearProgress className="" /> : null}</div>

              <div>
                {/* <------------------------------Img Detail From angular element------------------------------> */}
                <img-detail data={JSON.stringify(imgDetailData)}></img-detail>
              </div>

            </div>
          </div>
        </div>

        <DescriptionModification
        open={openDescriptionModificationModal}
        close={handleCloseDescriptionModification}
        detailid={_id}
        dynamicEndPoint= "api1/update-custom-meta-title"
        source="images"
        
      />


      </div>
    </>
  );
}

export default ImageDetails;
