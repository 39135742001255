import React, { useEffect, useState } from 'react'
import "../../index2.css";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import { clearBlogDetails, clearBlogListing, getdataforbloglist } from '../../BlogBackend/BlogReducer';
import { useDispatch, useSelector } from 'react-redux';
import { Button, LinearProgress, Tooltip } from '@mui/material';
import '../Blog/Blog.css';
import { clearBlogDetails, clearBlogListing, getdataforbloglist } from '../BlogBackend/BlogReducer';
import HelpIcon from '@mui/icons-material/Help'
import AddIcon from '@mui/icons-material/Add';
import DescriptionModification from './DescriptionModification';


const body = {
  "source": "",
  "condition": {
    "limit": 6,
    "skip": 0
  },
  "sort": {
    "type": "asc",
    "field": "priority"
  },
  "source": "blog_views",
  "searchcondition": {
    "status": 1
  },
  // "projection": {
  //   "createdon_datetime": 1,
  //   "image": 1,
  //   "title": 1
  // },
  relation: 'Frontend Details'
}

function BlogDetails() {
  // ---------------------------------------------Invoking Hooks--------------------------------------------- //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathParam = useParams();
  const { pathname } = useLocation();
  const { _id } = useParams();
  console.log("_id======>",_id);

  const [openDescriptionModificationModal,setDescriptionModificationModal] =useState(false); //for Description Modification for Meta

  const formSubmissionState = useSelector(state => state.formSlice.formSubmissionState.descriptionModification);

  const userType = useSelector((state)=>state.loginSlice.userInfo.type === "admin")

  // ----------------------------------------Global State from Redux---------------------------------------- //
  const resolved = useSelector(state => state.layoutSlice.resolved['/blog'] ? state.layoutSlice.resolved['/blog'] : false);//for resolve
  // const blogarray = useSelector(state => (state.blogSlice?.frontEndBlogData) ? state.blogSlice.frontEndBlogData : []);
  const { blogDetailArr, singleBlogDetail } = useSelector(state => (state.blogSlice?.frontEndBlogDetail) ? state.blogSlice.frontEndBlogDetail : {});
  const loading = useSelector(state => state.blogSlice?.loading);

  // ----------------------------------------Components Private State---------------------------------------- //
  const [blogDetail, setBlogDetail] = useState(null)

  // --------------------------------------------Other Veriables-------------------------------------------- //
  const pathWithoutParam = pathname.slice(1, pathname.lastIndexOf('/'));

  // -------------------------------------------useEffect Section------------------------------------------- //
  useEffect(() => {
    console.log("pathParam", pathParam);
    if (!resolved && blogDetailArr.length === 0) {
      // dispatch(clearBlogDetails("Frontend Details"));
      dispatch(getdataforbloglist({ ...body, searchcondition: { ...body.searchcondition, _id: { $ne: pathParam._id } } }));
    }
    return () => dispatch(clearBlogDetails("Frontend Details"));
  }, [])

  // For setting 'blog-detail-tube' element data
  useEffect(() => {
    if (blogDetailArr && blogDetailArr.length > 0 && singleBlogDetail && Object.keys(singleBlogDetail).length > 0) {

      console.log("singleBlogDetail *******", singleBlogDetail);
      // if(pathParam?._id && pathParam._id !=='') singleBlogDetail._id = pathParam._id;

      setBlogDetail({
        apiCall: {
          // apiUrl: "https://backend.galvestonspine.peceplatform.com/api/bloglisting",
          apiUrl: `${process.env.REACT_APP_API_URL}/api1/bloglisting`,
          body: { ...body, searchcondition: { ...body.searchcondition, _id: { $ne: singleBlogDetail._id } } }
        },
        detailPageUrl: pathWithoutParam,
        initialBlogArr: blogDetailArr,
        _id: pathParam?._id ? pathParam?._id : null,
        creationTime: singleBlogDetail.createdon_datetime,
        imgUrl: (singleBlogDetail.image[0]?.url) ? singleBlogDetail.image[0].url : "https://all-frontend-assets.s3.amazonaws.com/inland-empire-integrated-medicine-images/imgpsh_fullsize_anim-min.jpeg", //put default img link
        loaderPath: "https://all-frontend-assets.s3.amazonaws.com/inland-empire-integrated-medicine-images/imgpsh_fullsize_anim-min.jpeg",
        // video: { src: "https://pece-training-files.s3.amazonaws.com/uploadimage/_1667288971_Screencast2022-07-1312%3A57%3A38.mp4" },
        video: { id: singleBlogDetail?.youtubeVideo[0]?.id, thumbnail: singleBlogDetail?.youtubeVideo[0]?.thumbnail_link },
        title: singleBlogDetail.title,
        desc: singleBlogDetail.description,
        shareLink: ["Facebook", "Twitter", "Linkedin"]
      });
    }

  }, [blogDetailArr, singleBlogDetail]);

  useEffect(() => {
    console.log("singleBlogDetail ------------>", singleBlogDetail);
    let domNodeArr = null;
    setTimeout(() => {
      domNodeArr = document.querySelectorAll('blog-detail-tube');
      if (domNodeArr && domNodeArr.length > 0 && singleBlogDetail && Object.keys(singleBlogDetail).length > 0) {
        for (let i = 0; i < domNodeArr.length; i++) {
          if (domNodeArr[i]) domNodeArr[i].addEventListener('blog-detail-tube-event', (e) => {
            let eventData = JSON.parse(e.target.getAttribute('elem-event-data'));
            console.log("blog-detail-tube elem event Data --------->", eventData);
            switch (eventData.type) {
              case "read_more": {
                navigate(`/blog-detail/${eventData._id}`);
                window.location.reload();
              }
                break;
              case "shareInFacebook": {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_API_EXTERNAL_URL}${pathWithoutParam}/${eventData._id}`, "", "width=500,height=300");
              }
                break;
              case "shareInTwitter": {
                window.open(`https://twitter.com/intent/tweet?url=${process.env.REACT_APP_API_EXTERNAL_URL}${pathWithoutParam}/${eventData._id}`, "", "width=500,height=300");
              }
                break;
              case "shareInLinkedin": {
                window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${process.env.REACT_APP_API_EXTERNAL_URL}${pathWithoutParam}/${eventData._id}`, "", "width=500,height=300");
              }
                break;
              default: console.log("Event emited from blog-card element");
                break;
            }
          });

        }
      }
    }, 1000);

  }, [singleBlogDetail, blogDetailArr]);

  const handleOpenDescriptionModification = () => {
    setDescriptionModificationModal(true);
  };
  const handleCloseDescriptionModification = () => {
    setDescriptionModificationModal(false);
  };



  useEffect(() => {
   
    if (formSubmissionState && formSubmissionState == 2) {
      setDescriptionModificationModal(false);
    
      
    }
  }, [formSubmissionState]);



  //----------------------------------------------return-----------------------------------------------//
  return (
    <>

      <div className="blog_img">
        <img src="https://all-frontend-assets.s3.amazonaws.com/IEIM/ieim_blog_banner.webp" alt="" />
      </div>


      <div className='yutb_blog_dtls_main block-padding'>



        <div className="blog_section_mainwrapper blog_mainwrapper">
          <div className="common_width">
            <div className="blog_subwrapper">
              <h1 >Check out our latest uploaded blogs of Inland Empire Integrated Medicine</h1>


              {userType &&<div className="add_testimonial_btnwrapper">
              <Button className="blog_details"    onClick={() => handleOpenDescriptionModification()}>Coustom Meta</Button>

          
          </div>}

              {/* <------------------------------Blog Card List From angular element------------------------------> */}
              {(loading && typeof loading !== undefined) ?
                <div className="LinearProgress_div"> <LinearProgress /></div>
                : (blogDetail ? <blog-detail-tube data={JSON.stringify(blogDetail)}></blog-detail-tube> : null)}
            </div>
          </div>
        </div>

        <DescriptionModification
        open={openDescriptionModificationModal}
        close={handleCloseDescriptionModification}
        detailid={_id}
        dynamicEndPoint= "api1/update-custom-meta-title"
        source="blogs"
        />

      </div>
    </>


  )
}

export default BlogDetails