import { Box, Checkbox, Chip, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import store from '../../store'
import { resetFormFieldError, resetOtherData, resetOtherSelectFlag, resetTempFormUpdateData, setOtherSelectFlag } from '../formReducer'

export const SelectField = ({ fielddata, value, setValue, getValues, errors, clearErrors, formId, setError }) => {
  const [val, setVal] = useState(null)
  const [customError, setCustomError] = useState(null)

  const otherVal = useSelector((state) => (state.formSlice.otherData[formId] && state.formSlice.otherData[formId][fielddata.name]) ? state.formSlice.otherData[formId][fielddata.name] : null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (otherVal !== null) {
      if (fielddata.multiple !== undefined && fielddata.multiple === true) {
        if (getValues(fielddata.name) && Array.isArray(getValues(fielddata.name))) setValue(fielddata.name, [...getValues(fielddata.name), otherVal]);
        else setValue(fielddata.name, [otherVal])
      }
      if (fielddata.multiple !== undefined && fielddata.multiple === false) {
        setValue(fielddata.name, otherVal);
      }
      dispatch(resetOtherData({ formId, fieldName: fielddata.name }))
    }
  }, [otherVal])
  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue) {
      if (fielddata.multiple !== undefined && fielddata.multiple === true) setValue(fielddata.name, fielddata.defaultValue);
      else setValue(fielddata.name, fielddata.defaultValue);
    }

  }, []);

  useEffect(() => {
    if (val !== null) {
      if (fielddata.multiple) setValue(fielddata.name, [...getValues(fielddata.name), ...val]);
      else setValue(fielddata.name, val)
      dispatch(resetTempFormUpdateData({ formId, fieldName: fielddata.name }))
    }
  }, [val])

  //////////////////////////////////////// Effect the field error from outside //////////////////////////////
  useEffect(() => {
    if (customError !== null) {
      setError(fielddata.name, customError);
      dispatch(resetFormFieldError({ formId, fieldName: fielddata.name }))
    }
  }, [customError])

  store.subscribe(() => {
    const storeData = store.getState();

    if (Object.keys(storeData.formSlice.tempFormUpdateData).length > 0 && storeData.formSlice.tempFormUpdateData[formId] !== undefined && storeData.formSlice.tempFormUpdateData[formId][fielddata.name] !== undefined) {
      console.log("storing====>")
      setVal(storeData.formSlice.tempFormUpdateData[formId][fielddata.name])
    }

    //////////////////////////////////////////// Set Error from Outside ///////////////////////////////////////////////////////////
    if (Object.keys(storeData.formSlice.tempFormFieldError).length > 0 && storeData.formSlice.tempFormFieldError[formId] !== undefined && storeData.formSlice.tempFormFieldError[formId][fielddata.name] !== undefined) {
      console.log("storing====>")
      setCustomError(storeData.formSlice.tempFormFieldError[formId][fielddata.name])
    }
  })

  // const deletechipdata=(val)=>{
  //     console.log("val in chip",val);
  // }

  return (
    <div className={fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl
        sx={fielddata.sx}
      >
        {/* Label */}
        {/* <p>{JSON.stringify(fielddata.values.find(cur => cur.val == "cardiology")?.name,null,2)}</p>
        <p>{JSON.stringify(fielddata.values.find(cur => cur.val == "neurology"),null,2)}</p>
        <p>{JSON.stringify(fielddata.values.find(cur => cur.val == "pediatry"),null,2)}</p>
        <p>{JSON.stringify(fielddata.values.find(cur => cur.val == "dermatology_woundcare"),null,2)}</p>
        <p>{JSON.stringify(fielddata.values.find(cur => cur.val == "generalpractitioner"),null,2)}</p>
        <p>{JSON.stringify(fielddata.values.find(cur => cur.val == "preventivemedicine"),null,2)}</p> */}
        {fielddata.label && <InputLabel >{fielddata.label}</InputLabel>}
        <Select
          label={fielddata.label}
          multiple={fielddata.multiple !== undefined && typeof (fielddata.multiple) === "boolean" ? fielddata.multiple : false}
          value={getValues(fielddata.name) ? getValues(fielddata.name) : (fielddata.multiple === true ? [] : '')}
          onChange={(e) => {
            clearErrors(fielddata.name); // Clearing errors on focus or change
            if (e.target.value == 'other001') {
              dispatch(setOtherSelectFlag({ fieldName: fielddata.name, formId }));
              setTimeout(() => dispatch(resetOtherSelectFlag({ fieldName: fielddata.name, formId })), 1000)
              return;
            }
            setValue(fielddata.name, e.target.value);
          }}
          renderValue={(selected) => (
            <Box component={Array.isArray(selected) ? 'div' : 'span'} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {Array.isArray(selected) ? selected.map((value) => {
                // console.log('selected=====>', selected,getValues(fielddata.name))
                // console.log('selected=====>', fielddata.values,selected)
                // console.log('selected=====>2', fielddata.values.find(cur => cur.val==value))

                // console.log('selected=====>1',getValues(fielddata.name))

                return (
                  
                  <Chip key={value} label={fielddata.values.find(cur => cur.val == value)?.name} />
                )
              }) : fielddata.values.find(cur => cur.val == selected)?.name}
            </Box>
          )}
        >
          {!fielddata.multiple && <MenuItem value="">
            <ListItemText primary="None" />
          </MenuItem>}
          {fielddata.values.map((value, index) => (<MenuItem key={index} value={value.val}>
            {fielddata.checkbox && (
              <Checkbox checked={getValues(fielddata.name) ? ((fielddata.multiple == true) ? getValues(fielddata.name)?.includes(value.val) : (getValues(fielddata.name) == value.val)) : false} />
            )}
            <ListItemText primary={value.name} />
          </MenuItem>))}
          {fielddata.other == true && <MenuItem value='other001'>
            {/* {fielddata.checkbox && (
              <Checkbox checked={getValues(fielddata.name) ? ((fielddata.multiple == true) ? getValues(fielddata.name)?.includes(value.val) : (getValues(fielddata.name) == value.val)) : false} />
            )} */}
            <ListItemText primary='Other' />
          </MenuItem>}
        </Select>
        {/* ERROR */}
        {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
      </FormControl>
    </div>
  )
}
